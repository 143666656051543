/* eslint-disable import/prefer-default-export */
import { useState } from "react";
import { CorpBusinessAccount } from "redmond";
import {
  HAS_CHOSEN_ACCOUNT,
  onboardingPage,
  PATH_CORP_ACKNOWLEDGEMENT,
  VXB,
} from "@capone/common";
import { useHistory } from "react-router-dom";
import { changeBusinessInUse } from "b2b-base/src/api/v1/multi-account/changeBusinessInUse";
import { recentlyViewedAxiosCancelToken } from "b2b-base/src/api/config";

export const useSwitchBusinessAccount = () => {
  const history = useHistory();
  const [isAccountSwitchLoading, setIsAccountSwitchLoading] = useState(false);

  const handleChangeBusinessInUse = (selectedAccount: CorpBusinessAccount) => {
    setIsAccountSwitchLoading(true);
    sessionStorage.setItem(HAS_CHOSEN_ACCOUNT, "true");
    if (selectedAccount?.optedIn) {
      recentlyViewedAxiosCancelToken.cancel();
      changeBusinessInUse({
        businessId: selectedAccount.optedIn.businessId,
      })
        .then((response) => {
          setIsAccountSwitchLoading(false);
          window.location.href = response.request.responseURL;
        })
        .catch(() => {
          setIsAccountSwitchLoading(false);
        });
    } else if (selectedAccount?.rewardsAccount.productDisplayName === VXB) {
      history.push(PATH_CORP_ACKNOWLEDGEMENT);
    } else {
      window.location.replace(onboardingPage);
    }
  };

  return {
    handleChangeBusinessInUse,
    isAccountSwitchLoading,
  };
};
