import { Box } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import "./styles.scss";
import Skeleton from "react-loading-skeleton";
import { useDeviceTypes } from "halifax";

export const RecentlySearchedSkeletonCard = () => {
  const { matchesMobile } = useDeviceTypes();
  return (
    <Box
      className={clsx("recently-searched-card-skeleton-root", {
        mobile: matchesMobile,
      })}
    >
      <Skeleton className="recently-searched-card-skeleton-image" />
      <Box className="recently-searched-card-skeleton-title-container">
        <Skeleton className="recently-searched-card-skeleton-title" />
        <Skeleton className="recently-searched-card-skeleton-subtitle" />
      </Box>
    </Box>
  );
};
