import React, { useMemo, useState, useEffect } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import {
  AvailabilityRequestEnum,
  AvailabilityResponse,
  CallState,
  HotelEntryTypeEnum,
  Lodging,
  LodgingSelection,
  LodgingSelectionEnum,
  PlatformEnum,
  ProductFeedTileTappedProperties,
  PRODUCT_FEED_TILE_TAPPED,
  RecentHotelSearch,
  RewardsAccount,
  RecentFlightSearch,
  TripCategory,
  FlightEntryTypeEnum,
  RecentCarSearch,
  CarEntryTypeEnum,
} from "redmond";
import {
  ActionButton,
  B2BSpinner,
  capitalize,
  getTotalPriceText,
  genericPriceFormatter,
  Icon,
  IconName,
  Tag,
  useDeviceTypes,
} from "halifax";
import dayjs from "dayjs";
import "./styles.scss";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedCarsAvailabilityQuery,
  transformToStringifiedFlightShopQuery,
} from "../../../../utils/queryStringHelpers";
import {
  PATH_CARS_AVAILABILITY,
  PATH_FLIGHTS_SHOP,
  PATH_HOTELS_AVAILABILITY,
} from "../../../../utils/urlPaths";
import { useHistory } from "react-router-dom";
import { getEarnTagText } from "../../textConstants";
import { fetchHotelAvailability } from "../../../../api/v1/recently-viewed-and-searched/fetchHotelAvailability";
import {
  RecentlyViewedDesktopP0PropVariantType,
  RecentlyViewedMobileP0PropVariantType,
  RECENTLY_VIEWED_DESKTOP_P0_V1,
  RECENTLY_VIEWED_DESKTOP_P0_V2,
  RECENTLY_VIEWED_MOBILE_P0_V2,
  RECENTLY_VIEWED_MOBILE_P0_V3,
} from "../../../../context/experiments";
import { trackEvent } from "../../../../api/v1/trackEvent";
import {
  getRecentlySearchedCardTitle,
  getViewAllCTAText,
} from "./textConstants";
import clsx from "clsx";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";

export interface IRecentlySearchedCardProps {
  recentSearch: RecentHotelSearch | RecentFlightSearch | RecentCarSearch;
  largestValueAccount: RewardsAccount;
  variant:
    | RecentlyViewedDesktopP0PropVariantType
    | RecentlyViewedMobileP0PropVariantType;
  lodgingSelection?: LodgingSelection;
  uiVariant: "hotels" | "flights" | "cars";
}

export const RecentlySearchedCard = (props: IRecentlySearchedCardProps) => {
  const {
    recentSearch,
    largestValueAccount,
    variant,
    lodgingSelection,
    uiVariant,
  } = props;

  const [lodgings, setLodgings] = useState<Lodging[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );
  const [availabilityCallState, setAvailabilityCallState] = useState<CallState>(
    CallState.NotCalled
  );

  const history = useHistory();

  const { matchesMobile } = useDeviceTypes();

  const onViewAllHotelsCTAClick = () => {
    if ("location" in recentSearch && uiVariant === "hotels") {
      const recentlySearchedHotelProps: ProductFeedTileTappedProperties = {
        feed_type: "recently_viewed",
        tile_type: "recently_searched_hotel",
        feed_placement: "homepage",
      };
      trackEvent({
        eventName: PRODUCT_FEED_TILE_TAPPED,
        properties: {
          ...recentlySearchedHotelProps,
        },
      });
      history.push(
        `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          recentSearch.location,
          recentSearch.checkInDate,
          recentSearch.checkOutDate,
          recentSearch.numAdults,
          recentSearch.childrenAges.length
            ? recentSearch.childrenAges
            : Array(recentSearch.numChildren).fill(17),
          recentSearch.numRooms,
          HotelEntryTypeEnum.RECENTLY_SEARCHED_HOTEL
        )}`
      );
    } else if ("departureDate" in recentSearch && uiVariant === "flights") {
      const recentlySearchedFlightProps: ProductFeedTileTappedProperties = {
        feed_type: "recently_viewed",
        tile_type: "recently_searched_air",
        feed_placement: "homepage",
      };
      trackEvent({
        eventName: PRODUCT_FEED_TILE_TAPPED,
        properties: {
          ...recentlySearchedFlightProps,
        },
      });
      const event = new Event("clicked_recent_flight_search_card");
      dispatchEvent(event);
      history.push(
        `${PATH_FLIGHTS_SHOP}${transformToStringifiedFlightShopQuery({
          tripCategory: recentSearch.returnDate
            ? TripCategory.ROUND_TRIP
            : TripCategory.ONE_WAY,
          origin: recentSearch.originLocation,
          destination: recentSearch.destinationLocation,
          departureDate: recentSearch.departureDate,
          returnDate: recentSearch.returnDate,
          stopsOptions: undefined,
          noLCC: false,
          flightShopProgress: undefined,
          adultsCounts: recentSearch.numAdults,
          childrenCount: recentSearch.numChildren,
          infantsInSeatsCount: recentSearch.numInfantsSeat,
          infantsOnLapCount: recentSearch.numInfantsLap,
          entryPoint: FlightEntryTypeEnum.RECENTLY_SEARCHED_AIR,
          fareClass: recentSearch.fareClass,
        })}`
      );
    } else if ("driverAge" in recentSearch && uiVariant === "cars") {
      const recentlySearchedFlightProps: ProductFeedTileTappedProperties = {
        feed_type: "recently_viewed",
        tile_type: "recently_searched_car",
        feed_placement: "homepage",
      };
      trackEvent({
        eventName: PRODUCT_FEED_TILE_TAPPED,
        properties: {
          ...recentlySearchedFlightProps,
        },
      });
      history.push(
        `${PATH_CARS_AVAILABILITY}${transformToStringifiedCarsAvailabilityQuery(
          {
            dropOffDate: recentSearch.dropOffDate,
            dropOffTime: recentSearch.dropOffTime,
            dropOffLocation: recentSearch.dropOffLocation,
            pickUpDate: recentSearch.pickUpDate,
            pickUpTime: recentSearch.pickUpTime,
            pickUpLocation: recentSearch.pickUpLocation,
            driverAge: recentSearch.driverAge,
            entryPoint: CarEntryTypeEnum.RECENTLY_SEARCHED_CAR,
          }
        )}`
      );
    }
  };

  const locationNameToUse = useMemo(() => {
    if ("location" in recentSearch && uiVariant === "hotels") {
      const [locationName] = recentSearch.location
        ? recentSearch.location.split(",")
        : [];
      return locationName;
    }
    if ("pickUpLocationLabel" in recentSearch && uiVariant === "cars") {
      const [locationName] = recentSearch.pickUpLocationLabel
        ? recentSearch.pickUpLocationLabel.split(",")
        : [];
      return locationName;
    } else {
      return undefined;
    }
  }, [recentSearch, uiVariant]);

  const originNameToUse = useMemo(() => {
    if ("originLocationLabel" in recentSearch && uiVariant === "flights") {
      const [locationName] = recentSearch.originLocationLabel
        ? recentSearch.originLocationLabel.split(",")
        : [];
      return locationName;
    } else {
      return undefined;
    }
  }, [recentSearch, uiVariant]);

  const destinationNameToUse = useMemo(() => {
    if ("destinationLocationLabel" in recentSearch && uiVariant === "flights") {
      const [locationName] = recentSearch.destinationLocationLabel
        ? recentSearch.destinationLocationLabel.split(",")
        : [];
      return locationName;
    } else {
      return undefined;
    }
  }, [recentSearch, uiVariant]);

  useEffect(() => {
    if (lodgingSelection && uiVariant === "hotels") {
      const { numAdults, checkOutDate, checkInDate, numRooms } =
        recentSearch as RecentHotelSearch;
      if (
        recentSearch &&
        (lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
          ? !!lodgingSelection.placeId // checks if placeId is not an empty string, if it's an empty string, autocomplete failed and the map is using the manually created value that works for recently viewed
          : true) &&
        (variant === RECENTLY_VIEWED_DESKTOP_P0_V1 ||
          variant === RECENTLY_VIEWED_DESKTOP_P0_V2) &&
        availabilityCallState === CallState.NotCalled
      ) {
        const fetchInitialHotelAvailability = async () => {
          const availabilityResponse: AvailabilityResponse =
            await fetchHotelAvailability({
              lodgingSelection: lodgingSelection,
              stayDates: {
                from: checkInDate,
                until: checkOutDate,
              },
              guests: {
                adults: numAdults,
                children: [],
              },
              platform: PlatformEnum.Desktop,
              progressiveConfig: {},
              AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
              rooms: { numberOfRooms: numRooms },
            });
          setAvailabilityCallState(CallState.InProcess);
          setLodgings(availabilityResponse.lodgings);
          if (availabilityResponse.nextPageToken) {
            setNextPageToken(availabilityResponse.nextPageToken);
          } else {
            setAvailabilityCallState(CallState.Success);
          }
        };
        fetchInitialHotelAvailability();
      }
    }
  }, [
    lodgingSelection,
    recentSearch,
    variant,
    availabilityCallState,
    uiVariant,
  ]);

  useEffect(() => {
    if (
      nextPageToken &&
      (variant === RECENTLY_VIEWED_DESKTOP_P0_V1 ||
        variant === RECENTLY_VIEWED_DESKTOP_P0_V2)
    ) {
      const fetchFollowUpHotelAvailability = async () => {
        const availabilityResponse: AvailabilityResponse =
          await fetchHotelAvailability({
            moreToken: nextPageToken,
            progressiveConfig: {},
            AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch,
          });
        setLodgings((prevState) => {
          return [...prevState, ...availabilityResponse.lodgings];
        });
        if (availabilityResponse.nextPageToken) {
          setNextPageToken(availabilityResponse.nextPageToken);
        } else {
          setNextPageToken(undefined);
          setAvailabilityCallState(CallState.Success);
        }
      };
      fetchFollowUpHotelAvailability();
    } else {
      return;
    }
  }, [nextPageToken, variant]);

  const sortedLodgings = useMemo(() => {
    if (lodgings.length && !nextPageToken) {
      return lodgings
        .filter((lodging) => !!lodging.available && !!lodging.price)
        .sort((l1: Lodging, l2: Lodging) => {
          return (
            l1.price!.nightlyPrice.fiat.value -
            l2.price!.nightlyPrice.fiat.value
          );
        });
    } else {
      return;
    }
  }, [lodgings, nextPageToken]);

  const cardTitle = useMemo(() => {
    return getRecentlySearchedCardTitle(
      uiVariant,
      locationNameToUse,
      originNameToUse,
      destinationNameToUse
    );
  }, [uiVariant, locationNameToUse, originNameToUse, destinationNameToUse]);

  const viewAllCTAText = useMemo(() => {
    if (uiVariant !== "flights" && locationNameToUse) {
      return getViewAllCTAText(uiVariant, locationNameToUse);
    } else if (uiVariant === "flights" && destinationNameToUse) {
      return getViewAllCTAText(uiVariant, destinationNameToUse);
    } else {
      return undefined;
    }
  }, [uiVariant, locationNameToUse, destinationNameToUse]);

  const isFlightsRoundTrip = useMemo(() => {
    return !!(recentSearch as RecentFlightSearch).returnDate;
  }, [recentSearch]);

  const searchedDatesText = useMemo(() => {
    if (uiVariant === "hotels") {
      return `${
        dayjs((recentSearch as RecentHotelSearch).checkInDate).format("MMMM")
          .length > 4
          ? dayjs((recentSearch as RecentHotelSearch).checkInDate).format(
              "MMM D"
            )
          : dayjs((recentSearch as RecentHotelSearch).checkInDate).format(
              "MMMM D"
            )
      } - ${
        dayjs((recentSearch as RecentHotelSearch).checkOutDate).format("MMMM")
          .length > 4
          ? dayjs((recentSearch as RecentHotelSearch).checkOutDate).format(
              "MMM D"
            )
          : dayjs((recentSearch as RecentHotelSearch).checkOutDate).format(
              "MMMM D"
            )
      }`;
    } else if (uiVariant === "flights") {
      let dateString = `${
        dayjs((recentSearch as RecentFlightSearch).departureDate).format("MMMM")
          .length > 4
          ? dayjs((recentSearch as RecentFlightSearch).departureDate).format(
              "MMM D"
            )
          : dayjs((recentSearch as RecentFlightSearch).departureDate).format(
              "MMMM D"
            )
      }`;
      if (!!(recentSearch as RecentFlightSearch).returnDate) {
        dateString += ` - ${
          dayjs((recentSearch as RecentFlightSearch).returnDate).format("MMMM")
            .length > 4
            ? dayjs((recentSearch as RecentFlightSearch).returnDate).format(
                "MMM D"
              )
            : dayjs((recentSearch as RecentFlightSearch).returnDate).format(
                "MMMM D"
              )
        }`;
      }
      return dateString;
    } else if (uiVariant === "cars") {
      let dateString = `${
        dayjs((recentSearch as RecentCarSearch).pickUpDate).format("MMMM")
          .length > 4
          ? dayjs((recentSearch as RecentCarSearch).pickUpDate).format("MMM D")
          : dayjs((recentSearch as RecentCarSearch).pickUpDate).format("MMMM D")
      } - ${
        dayjs((recentSearch as RecentCarSearch).dropOffDate).format("MMMM")
          .length > 4
          ? dayjs((recentSearch as RecentCarSearch).dropOffDate).format("MMM D")
          : dayjs((recentSearch as RecentCarSearch).dropOffDate).format(
              "MMMM D"
            )
      }`;
      return dateString;
    } else {
      return undefined;
    }
  }, [uiVariant, recentSearch]);

  const earnTagText = useMemo(() => {
    if (uiVariant && largestValueAccount) {
      return getEarnTagText(
        largestValueAccount,
        largestValueAccount.rewardsBalance.currencyDescription ??
          largestValueAccount.rewardsBalance.currency,
        uiVariant
      );
    } else {
      return undefined;
    }
  }, [uiVariant, largestValueAccount]);

  const fareClassText = useMemo(() => {
    if ("fareClass" in recentSearch && recentSearch.fareClass?.length) {
      return `${capitalize(recentSearch.fareClass[0])}${
        recentSearch.fareClass.length > 1
          ? ` +${recentSearch.fareClass.length - 1}`
          : ""
      }`;
    }

    return undefined;
  }, [recentSearch]);

  const isCorporate = isCorpTenant(config.TENANT);

  if (!cardTitle || !viewAllCTAText || !searchedDatesText) {
    return null;
  }

  return (
    <Link
      className="recently-searched-card-wrapper"
      onClick={onViewAllHotelsCTAClick}
    >
      <Box
        className={clsx("recently-searched-card-root", {
          hotels: uiVariant === "hotels",
          flights: uiVariant === "flights",
          cars: uiVariant === "cars",
        })}
      >
        {earnTagText && (
          <Box className="earn-tag-container">
            {!(
              matchesMobile &&
              [
                RECENTLY_VIEWED_MOBILE_P0_V2,
                RECENTLY_VIEWED_MOBILE_P0_V3,
              ].includes(variant)
            ) && (
              <Tag
                className="earn-tag b2b"
                label={
                  <>
                    <Icon name={IconName.StarIcon} />
                    <Typography
                      className="earn-tag-text"
                      dangerouslySetInnerHTML={{
                        __html: earnTagText,
                      }}
                    />
                  </>
                }
              />
            )}
          </Box>
        )}
        {((uiVariant === "hotels" &&
          matchesMobile &&
          [RECENTLY_VIEWED_MOBILE_P0_V2, RECENTLY_VIEWED_MOBILE_P0_V3].includes(
            variant
          )) ||
          uiVariant !== "hotels") && <Box className="img-container" />}

        <Box className="recent-search-info">
          <Box className="searched-location">
            {uiVariant === "hotels" &&
              !(
                matchesMobile &&
                [
                  RECENTLY_VIEWED_MOBILE_P0_V2,
                  RECENTLY_VIEWED_MOBILE_P0_V3,
                ].includes(variant)
              ) && <Icon name={IconName.HotelFunnelIcon} />}
            <Typography className="location-text">{cardTitle}</Typography>
          </Box>
          {uiVariant === "flights" && (
            <div
              className={clsx("search-params-info", {
                corporate: isCorporate,
                mobile: matchesMobile,
              })}
            >
              <Typography variant="body2" className="trip-category-text">
                {isFlightsRoundTrip ? "Round-trip flight" : "One-way flight"}
              </Typography>
              {!matchesMobile && fareClassText && (
                <Typography variant="body2" className="fare-class-text">
                  | {fareClassText}
                </Typography>
              )}
            </div>
          )}
          <Box className="searched-dates">
            {uiVariant === "hotels" &&
              (!(
                matchesMobile &&
                [
                  RECENTLY_VIEWED_MOBILE_P0_V2,
                  RECENTLY_VIEWED_MOBILE_P0_V3,
                ].includes(variant)
              ) ? (
                <Icon name={IconName.Calendar} />
              ) : (
                <Icon name={IconName.HotelFunnelIcon} />
              ))}
            {uiVariant === "flights" && (
              <Icon name={IconName.FlightFunnelIcon} />
            )}
            {uiVariant === "cars" && <Icon name={IconName.CarFunnelIcon} />}

            <Typography className="searched-dates-text">
              {searchedDatesText}
            </Typography>
          </Box>
          {uiVariant === "hotels" &&
            (variant === RECENTLY_VIEWED_DESKTOP_P0_V1 ||
              variant === RECENTLY_VIEWED_DESKTOP_P0_V2) &&
            (lodgingSelection?.LodgingSelection === LodgingSelectionEnum.Place
              ? !!lodgingSelection.placeId // checks if placeId is not an empty string, if it's an empty string, autocomplete failed and the map is using the manually created value that works for recently viewed
              : true) &&
            !matchesMobile && (
              <Typography className="starting-price-text">
                Starting at{" "}
                {availabilityCallState === CallState.Success &&
                sortedLodgings?.length &&
                sortedLodgings[0].price?.nightlyPrice.fiat ? (
                  <>
                    {getTotalPriceText({
                      price: sortedLodgings[0].price?.nightlyPrice.fiat,
                      priceFormatter: genericPriceFormatter,
                    })}{" "}
                    <span className="per-night-text">per night</span>
                  </>
                ) : (
                  <span className="spinner-wrapper">
                    <B2BSpinner style={{ width: "15px", height: "15px" }} />
                  </span>
                )}
              </Typography>
            )}
        </Box>
        {!matchesMobile && (
          <ActionButton
            className="view-all-button b2b"
            onClick={onViewAllHotelsCTAClick}
            message={
              <Typography variant="h2" className="button-text">
                {viewAllCTAText}
              </Typography>
            }
          />
        )}
      </Box>
    </Link>
  );
};
