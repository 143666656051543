import axios from "axios";
import { BusinessModalName, BusinessModalNames, ModalDataMap } from "redmond";
import { cobtBusinessPrefix } from "../../paths";
import { config } from "../../config";

const END_POINT: string = `${cobtBusinessPrefix}/modal`;

interface ModalMetadataResponse {
  hasSeenModalMap: Record<BusinessModalName, boolean>;
  modalDataMap: { [K in BusinessModalNames]?: ModalDataMap[K] };
}

export const fetchBusinessModalMetadata = (): Promise<ModalMetadataResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(END_POINT, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
