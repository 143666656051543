import { useCorpPersonData } from "@capone/common/src/hooks/useCorpPersonData";
import isEqual from "lodash-es/isEqual";
import { CorpSessionInfo, ICorpPerson, IPerson, SessionInfo } from "redmond";
import { updateUserPassenger } from "../../../../api/v1/user-passengers/updateUserPassenger";
import updateUserDefaultTraveler from "../../../../api/v1/user/updateUserDefaultTraveler";

export const updateCorpPassengerList = async ({
  person,
  currentTravelers,
  source,
}: {
  person: IPerson;
  currentTravelers: IPerson[] | ICorpPerson[];
  sessionInfo: CorpSessionInfo | SessionInfo | undefined;
  source?: string;
}): Promise<IPerson> => {
  // Check if passport field is valid or filter it
  const { passport, ...restOfPayload } = person;
  const personToUpdate: IPerson = { ...restOfPayload };
  if (passport?.countryOfIssue?.length === 2) {
    personToUpdate.passport = passport;
  }

  const existingTraveler = currentTravelers.find(
    (traveler) => traveler.id === personToUpdate.id
  );
  const isNewTraveler: boolean = !existingTraveler;
  const [shouldAssignTraveler, deUserId] = useCorpPersonData(
    "isGhostUser" in person ? (person as ICorpPerson) : null
  );
  // remove undefined keys for comparison
  Object.keys(personToUpdate).forEach(
    (key: string) =>
      personToUpdate[key as keyof IPerson] === undefined &&
      delete personToUpdate[key as keyof IPerson]
  );
  const sameTravelerInfo: boolean = isEqual(existingTraveler, personToUpdate);

  let updatedTraveler = personToUpdate;
  if (!sameTravelerInfo) {
    const response = await updateUserPassenger({
      person: personToUpdate,
      isNewTraveler,
      source,
      entryPoint: "onboarding_modal",
    });

    updatedTraveler = response.person || personToUpdate;
  }

  if (shouldAssignTraveler) {
    await updateUserDefaultTraveler({
      travelerId: personToUpdate.id,
      userId: deUserId,
    });

    if ("isMissingInfo" in updatedTraveler) {
      (updatedTraveler as ICorpPerson).isMissingInfo = false;
    }
  }

  return updatedTraveler;
};
