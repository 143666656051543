import React, { useMemo } from "react";
import { Box, Link, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import { HotelAvailabilityCard, Icon, IconName, useDeviceTypes } from "halifax";
import {
  HotelEntryTypeEnum,
  LodgingSelection,
  ProductFeedTileTappedProperties,
  PRODUCT_FEED_TILE_TAPPED,
  RecentlyViewedHotel,
  RewardsAccount,
} from "redmond";
import { v4 as uuidv4 } from "uuid";
import { localCache } from "@capone/common";
import { transformToStringifiedQuery } from "../../../../utils/queryStringHelpers";
import { PATH_HOTELS_SHOP } from "../../../../utils/urlPaths";
import { getEarnTagText } from "../../textConstants";
import "./styles.scss";
import {
  RecentlyViewedDesktopP0PropVariantType,
  RecentlyViewedMobileP0PropVariantType,
  RECENTLY_VIEWED_MOBILE_P0_V2,
  RECENTLY_VIEWED_MOBILE_P0_V3,
} from "../../../../context/experiments";
import { trackEvent } from "../../../../api/v1/trackEvent";

export interface IRecentlyViewedCardProps {
  recentView: RecentlyViewedHotel;
  largestValueAccount: RewardsAccount;
  lodgingSelection: LodgingSelection;
  index: number;
  variant:
    | RecentlyViewedDesktopP0PropVariantType
    | RecentlyViewedMobileP0PropVariantType;
  onPCBannerClick: (event: React.MouseEvent<HTMLElement>) => void;
  isCacheHotelTokenEnabled?: boolean;
}

export const RecentlyViewedCard = (props: IRecentlyViewedCardProps) => {
  const {
    recentView,
    largestValueAccount,
    lodgingSelection,
    index,
    variant,
    onPCBannerClick,
    isCacheHotelTokenEnabled = false,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const onHotelCardClick = (recentlyViewedItem: RecentlyViewedHotel) => {
    const {
      lodging,
      checkInDate,
      checkOutDate,
      numAdults,
      numChildren,
      numRooms,
      childrenAges,
    } = recentlyViewedItem;
    const params = transformToStringifiedQuery({
      lodgingId: lodging.lodging.id,
      fromDate: checkInDate,
      untilDate: checkOutDate,
      adultsCount: numAdults,
      children: childrenAges.length
        ? childrenAges
        : Array(numChildren).fill(17),
      selectedAccountIndex: 0,
      selectedLodgingIndex: index,
      lodgingSelection,
      roomsCount: numRooms,
      fromHotelAvailability: false,
      entryPoint: HotelEntryTypeEnum.RECENTLY_VIEWED_HOTEL,
      shopToken: isCacheHotelTokenEnabled
        ? lodging.price?.opaqueShopRequest
        : localCache.set(uuidv4(), lodging.price?.opaqueShopRequest || "").key,
    });
    window.open(`${PATH_HOTELS_SHOP}${params}`, "_blank");
    const recentlyViewedProps: ProductFeedTileTappedProperties = {
      feed_type: "recently_viewed",
      tile_type: "recently_viewed_hotel",
      feed_placement: "homepage",
    };
    trackEvent({
      eventName: PRODUCT_FEED_TILE_TAPPED,
      properties: {
        ...recentlyViewedProps,
      },
    });
  };

  const locationNameToUse = useMemo(() => {
    const [locationName] = recentView.location
      ? recentView.location.split(",")
      : [];
    return locationName;
  }, [recentView]);

  const earnTagText = useMemo(() => {
    if (largestValueAccount) {
      return getEarnTagText(
        largestValueAccount,
        largestValueAccount.rewardsBalance.currencyDescription ??
          largestValueAccount.rewardsBalance.currency,
        "hotels"
      );
    }
    return undefined;
  }, [largestValueAccount]);

  return (
    <Link
      className="recently-viewed-hotel-availability-wrapper"
      onClick={() => onHotelCardClick(recentView)}
    >
      <HotelAvailabilityCard
        isMobile={matchesMobile}
        className="recently-viewed-hotel-availability-card-root"
        hotelAvailabilityInfo={{
          ...recentView.lodging,
          lodging: recentView.lodging.lodging,
          price: recentView.lodging.price,
          nightCount: dayjs(recentView.checkOutDate).diff(
            recentView.checkInDate,
            "days"
          ),
        }}
        isPremierCollection={!!recentView.isPremierCollection}
        onPCBannerClick={onPCBannerClick}
        variant={
          matchesMobile &&
          [RECENTLY_VIEWED_MOBILE_P0_V2, RECENTLY_VIEWED_MOBILE_P0_V3].includes(
            variant
          )
            ? "recently-viewed-minimal"
            : "recently-viewed"
        }
        onCTAClick={() => onHotelCardClick(recentView)}
        earnTagContent={
          earnTagText ? (
            <>
              <Icon name={IconName.StarIcon} />
              <Typography
                className="earn-tag-text"
                dangerouslySetInnerHTML={{
                  __html: earnTagText,
                }}
              />
            </>
          ) : undefined
        }
        earnTagClassName="b2b"
        ctaText={`View hotel in ${locationNameToUse}`}
        hotelDatesContent={
          <Box className="searched-dates">
            <Icon name={IconName.HotelFunnelIcon} />
            <Typography className="searched-dates-text">
              {dayjs(recentView.checkInDate).format("MMMM").length > 4
                ? dayjs(recentView.checkInDate).format("MMM D")
                : dayjs(recentView.checkInDate).format("MMMM D")}{" "}
              -{" "}
              {dayjs(recentView.checkOutDate).format("MMMM").length > 4
                ? dayjs(recentView.checkOutDate).format("MMM D")
                : dayjs(recentView.checkOutDate).format("MMMM D")}
            </Typography>
          </Box>
        }
      />
    </Link>
  );
};
