import { Box, DialogActions, DialogContent } from "@material-ui/core";
import { PolicyDetailsContent, ActionButton } from "halifax";
import React from "react";
import { PolicyTier, ProductType } from "redmond";
import Header from "../Header";
import { StepProps } from "../types";
import baseClasses from "../../styles.module.css";

export interface IProps extends StepProps {
  policies: PolicyTier;
  productType: ProductType;
}

export const ReviewPolicy = ({
  primaryAction,
  secondaryAction,
  onClose,
  policies,
  productType,
}: IProps): React.ReactElement => (
  <Box className={baseClasses.stepWrap}>
    <Header
      title="Review travel policy"
      subtitle="All options within these guidelines are included in your company travel policy."
      currentStep={2}
      onClose={onClose}
    />
    <DialogContent>
      <PolicyDetailsContent
        policies={policies}
        productType={productType}
      />
    </DialogContent>
    <DialogActions>
      <ActionButton
        dataTestId="onboarding-review-policy-secondary-cta"
        onClick={secondaryAction}
        defaultStyle="h4r-secondary"
        message="Back"
      />
      <ActionButton
        dataTestId="onboarding-review-policy-primary-cta"
        onClick={primaryAction}
        message="Got it!"
        defaultStyle="h4r-primary"
      />
    </DialogActions>
  </Box>
);
