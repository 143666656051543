import React from "react";
import { useCarsCheckoutProps } from "@capone/portal";
import CarsModule from "../../CarsModule";

export const LegacyCarsBooking = (
  props: React.ComponentProps<typeof CarsModule>
) => {
  const checkoutProps = useCarsCheckoutProps();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CarsModule {...props} core={checkoutProps} />;
};
