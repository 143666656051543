import React from "react";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { ActionLink, ActionButton } from "halifax";
import { StepProps } from "../types";
import logo from "./capone-logo-white.svg";
import classNames from "./styles.module.css";

const Welcome = ({
  primaryAction,
  secondaryAction,
  isLoading,
}: Omit<StepProps, "onClose"> & { isLoading: boolean }): React.ReactElement => (
  <Box className={classNames.container}>
    <Box className={classNames.image}>
      <img src={logo} alt="Capital One Logo" className={classNames.logo} />
    </Box>
    <Box className={classNames.content}>
      <DialogTitle id="onboarding-title" disableTypography>
        <Typography
          className={classNames.title}
          variant="h1"
          component="h1"
          tabIndex={0}
          autoFocus
        >
          Welcome
        </Typography>
      </DialogTitle>
      <DialogContent id="onboarding-description">
        <Typography className={classNames.subtitle} id="onboarding-subtitle">
          Complete these 3 simple steps and you’ll be booking with ease.
        </Typography>
        <Stepper orientation="vertical">
          <Step expanded active={false} disabled={false}>
            <StepLabel>Create your traveler profile</StepLabel>
            <StepContent>
              <Typography>
                Save your travel information to create a seamless booking
                experience for all your trips.
              </Typography>
            </StepContent>
          </Step>
          <Step expanded disabled={false}>
            <StepLabel>Save additional details</StepLabel>
            <StepContent>
              <Typography>
                Are you a member of airline or hotel loyalty programs? Add your
                loyalty and known traveler numbers to ensure they’re included in
                all bookings.
              </Typography>
            </StepContent>
          </Step>
          <Step expanded disabled={false}>
            <StepLabel>Review travel policy</StepLabel>
            <StepContent>
              <Typography>
                Your company policies and traveler information will be
                automatically applied to your booking experience. All that’s
                left for you to do is start traveling!
              </Typography>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>
      <DialogActions>
        <ActionLink
          onClick={secondaryAction}
          content="Skip to start browsing"
        />
        <ActionButton
          onClick={() => primaryAction()}
          defaultStyle="h4r-primary"
          className={classNames.welcome_button}
          message={
            isLoading ? (
              <CircularProgress size={14} color="inherit" />
            ) : (
              "Get started"
            )
          }
        />
      </DialogActions>
    </Box>
  </Box>
);

export default Welcome;
