import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import {
  CorpModalScreens,
  IPerson,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalAlertProperties,
  ModalButtonType,
  ModalCategoryType,
} from "redmond";
import { TripsTravelerInfoForm, CorporateButton } from "halifax";
import { ArrowBack } from "@material-ui/icons";
import { StepProps } from "../types";

import baseClasses from "../../styles.module.css";
import Header from "../Header";
import { trackEvent } from "../../../../../api/v1/trackEvent";

interface IProps extends Omit<StepProps, "primaryAction"> {
  primaryAction: (traveler: IPerson) => Promise<void>;
  traveler?: IPerson;
  isOnboardingRevampEnabled: boolean;
  isMobile?: boolean;
}

const useStyles = makeStyles(() => ({
  leftButtonWrapper: {
    "& .MuiButton-label": {
      justifyContent: "normal",
    },
  },
  mobileButton: {
    "& .MuiButton-label": {
      justifyContent: "center",
    },
  },
}));

const CreateTraveler = ({
  primaryAction,
  secondaryAction,
  onClose,
  traveler,
  isOnboardingRevampEnabled,
  isMobile = false,
}: IProps): React.ReactElement => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const resetSavingState = () => {
    setIsSaving(false);
  };

  const classes = useStyles();

  const isMissingInfo = Boolean(
    traveler && "isMissingInfo" in traveler && traveler.isMissingInfo
  );
  const titleTextPrefix = isMissingInfo || !traveler ? `Create` : `Confirm`;
  const modalTitle = `${titleTextPrefix} your traveler profile`;
  const subtitleCreateConfirm =
    isMissingInfo || !traveler ? `create` : `confirm`;
  const modalSubtitle = `First things first, let’s ${subtitleCreateConfirm} your traveler profile. Please make sure all the information added matches your government issued ID as this will be applied to travel bookings moving forward.`;

  const commonTrackingProperties = useMemo(
    () =>
      ({
        type: isOnboardingRevampEnabled ? "confirm_traveler_profile_onboarding" : "create_traveler_profile_onboarding",
        screen: CorpModalScreens.ONBOARDING_DETAILS,
        primary_button: "save and continue",
        secondary_button: "back",
        step: CorpModalScreens.ONBOARDING_DETAILS,
        funnel: "other",
        category: ModalCategoryType.FEATURE,
        modal_subtitle: modalSubtitle,
        modal_title: modalTitle,
        agent_title: modalTitle,
        agent_subtitle: modalSubtitle,
      } satisfies ModalAlertProperties),
    []
  );

  useEffect(() => {
    trackEvent({
      eventName: MODAL_ALERT,
      properties: commonTrackingProperties,
    });
  }, []);

  const handleSaveClick = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.PRIMARY,
      },
    });
    setIsSaving(true);
  };

  const back = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.SECONDARY,
      },
    });
    secondaryAction();
  };

  return (
    <Box className={baseClasses.stepWrap}>
      <Header
        title={modalTitle}
        subtitle={modalSubtitle}
        currentStep={0}
        onClose={onClose}
        isOnboardingRevampEnabled={isOnboardingRevampEnabled}
        isMobile={isMobile}
      />
      <DialogContent
        id="onboarding-description"
        style={isMobile ? { paddingLeft: 0, paddingRight: 0 } : undefined}
      >
        <Box
          style={isMobile ? { paddingLeft: 16, paddingRight: 16 } : undefined}
        >
          <TripsTravelerInfoForm
            traveler={traveler}
            handleUpdatePassenger={(updatedTraveler) => {
              primaryAction(updatedTraveler)
                .then(() => {
                  resetSavingState();
                })
                .catch(() => {
                  resetSavingState();
                });
            }}
            travelerInfoTitle="Traveler information"
            driverLicenseSectionSubtitle="This information will be required in order for you to book rental cars."
            buttonText="Save and continue"
            showGenderField
            showNationalityField
            showDriverLicenseSection
            disableDriversLicenseValidation
            showSecondaryButton
            secondaryButtonText="Back"
            onSecondaryButtonClick={secondaryAction}
            triggerSave={isSaving}
            setPrimaryButtonDisabled={setIsDisabled}
            hideButtons
            showPassportSection
            className={baseClasses.onboardingModalForm}
            isOnboarding
            showContactEmailSection
            tenant="capone-corporate"
            isOnboardingRevampEnabled={isOnboardingRevampEnabled}
            isMobile={isMobile}
          />
        </Box>
      </DialogContent>
      <DialogActions
        className={
          isMobile ? "mobile-dialog-actions" : "create-traveler-actions"
        }
        style={{
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          justifyContent:
            isOnboardingRevampEnabled && !isMobile
              ? "space-between"
              : "flex-end",
          width: "auto",
          alignItems: "center",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.15)",
          boxSizing: isMobile ? "border-box" : "content-box",
        }}
      >
        <CorporateButton
          className={
            isMobile ? classes.mobileButton : classes.leftButtonWrapper
          }
          onClick={back}
          size={isMobile ? "large" : "medium"}
          variant="text"
          icon={<ArrowBack fontSize="small" />}
          data-testid="create-traveler-profile-secondary-cta"
          fullWidth={isMobile}
          style={
            isMobile
              ? {
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }
              : {}
          }
        >
          Back
        </CorporateButton>
        <CorporateButton
          className={isMobile ? classes.mobileButton : ""}
          size={isMobile ? "large" : "medium"}
          onClick={handleSaveClick}
          disabled={isDisabled}
          data-testid="create-traveler-profile-primary-cta"
          fullWidth={isMobile}
          style={isMobile ? { marginBottom: "16px" } : {}}
        >
          {isSaving ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            `Save and continue`
          )}
        </CorporateButton>
      </DialogActions>
    </Box>
  );
};

export default CreateTraveler;
