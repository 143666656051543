import axios from "axios";
import { config, recentlyViewedAxiosCancelToken } from "../../config";
import { recentlySearchedApiPrefix } from "../../paths";
import { RecentlySearchedFlightsResponse } from "redmond";

const END_POINT: string = `${recentlySearchedApiPrefix}/flights`;

export const fetchRecentlySearchedFlights =
  (): Promise<RecentlySearchedFlightsResponse> =>
    new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(END_POINT, {
          baseURL: config.baseURL,
          cancelToken: recentlyViewedAxiosCancelToken.token,
        });
        resolve(res.data);
      } catch (e) {
        if (axios.isCancel(e)) {
          // do not reject if the error is a cancellation from the cancel token
          return;
        }
        reject(e);
      }
    });
