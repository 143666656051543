import React from "react";

export interface ImagePosition {
  desktop?: string;
  mobile?: string;
}

export interface ValuePropCard {
  key: string;
  isPrimary: boolean;
  imageUrl: string;
  imagePosition?: ImagePosition;
  description: JSX.Element;
}

const VALUE_PROP_CARDS: ValuePropCard[] = [
  {
    key: "cashback-value-prop",
    isPrimary: true,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/cashback-value-prop.webp",
    description: (
      <>
        Enjoy <strong>unlimited 5% cash back</strong> on hotels & cars.
      </>
    ),
  },
  {
    key: "corp-rates-value-prop",
    isPrimary: false,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/corp-rates-value-prop.webp",
    description: (
      <>
        <strong>Save up to 10%</strong> on hotels with our discounted corporate
        rates.
      </>
    ),
  },
  {
    key: "southwest-value-prop",
    isPrimary: false,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/southwest-value-prop.webp",
    imagePosition: {
      desktop: "40%",
      mobile: "30%",
    },
    description: (
      <>
        Fly <strong>Southwest</strong> with our exclusive access for business
        travelers.
      </>
    ),
  },
  {
    key: "save-time-value-prop",
    isPrimary: true,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/save-time-value-prop.webp",
    imagePosition: {
      desktop: "70%",
      mobile: "80%",
    },
    description: (
      <>
        Save time by managing travelers, bookings and expenses{" "}
        <strong>on 1 platform.</strong>
      </>
    ),
  },
  {
    key: "rebook-value-prop",
    isPrimary: false,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/rebook-value-prop.webp",
    description: (
      <>
        <strong>Rebook your disrupted flight or get a refund</strong> with our
        business-grade flexibility options.
      </>
    ),
  },
  {
    key: "cancel-options-value-prop",
    isPrimary: true,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/cancel-options-value-prop.webp",
    description: (
      <>
        Add the option to <strong>cancel your flight or hotel</strong> for work
        reasons, life reasons or any reason.
      </>
    ),
  },
  {
    key: "support-value-prop",
    isPrimary: false,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/support-value-prop.webp",
    description: (
      <>
        Reach <strong>24/7 live support</strong> with little to no wait time.
      </>
    ),
  },
  {
    key: "price-prediction-value-prop",
    isPrimary: false,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/price-prediction-value-prop.webp",
    imagePosition: {
      desktop: "85%",
      mobile: "80%",
    },
    description: (
      <>
        <strong>Save an average of 15%</strong> on flights using price
        prediction.
      </>
    ),
  },
  {
    key: "loyalty-value-prop",
    isPrimary: false,
    imageUrl:
      "https://cdn.hopper.com/capone-corporate/homepage-value-prop/loyalty-value-prop.webp",
    description: (
      <>
        <strong>Earn loyalty points</strong> from your favorite hotels,
        airlines, and rental car brands.
      </>
    ),
  },
];

export default VALUE_PROP_CARDS;
