// TODO: remove entire Auth component once readAccess configs are ready on Olympus
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  B2B_PORTAL_AUTH_REDIRECT_TO,
  B2B_PORTAL_STARTSESSION_PATH,
} from "redmond";
import {
  UTM_SOURCE_PARAM,
  USER_SOURCE_KEY,
  UTM_MEDIUM_PARAM,
  USER_MEDIUM_KEY,
} from "@capone/common";
import { logger } from "../../Logger";
interface IJWTAuth {
  children?: React.ReactNode;
}

const JWTAuth = ({ children }: IJWTAuth) => {
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const ASSERTION_PARAM = "assertion";
  const assertion = query.get(ASSERTION_PARAM) || undefined;

  const queryParams = new URLSearchParams(location.search);
  queryParams.delete(ASSERTION_PARAM);

  React.useEffect(() => {
    if (assertion && typeof assertion == "string") {
      sessionStorage.setItem(
        B2B_PORTAL_AUTH_REDIRECT_TO,
        `${location.pathname}?${queryParams.toString()}`
      );
      const param = queryParams.get(UTM_SOURCE_PARAM);
      if (param) {
        sessionStorage.setItem(USER_SOURCE_KEY, param);
      }

      const mediumParam = queryParams.get(UTM_MEDIUM_PARAM);
      if (mediumParam) {
        sessionStorage.setItem(USER_MEDIUM_KEY, mediumParam);
      }

      logger()?.info(
        `RedirectTo for JWT: ${sessionStorage.getItem(
          B2B_PORTAL_AUTH_REDIRECT_TO
        )}`,
        {
          redirectTo: sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO),
        }
      );

      history.replace(`${B2B_PORTAL_STARTSESSION_PATH}?jwt=${assertion}`);
    }
  }, []);

  if (assertion && typeof assertion == "string") return null;
  return <>{children}</>;
};

export default JWTAuth;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
