import React, { useCallback, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Box, Dialog } from "@material-ui/core";

import {
  IPerson,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalAlertChoiceProperties,
  ModalAlertProperties,
  ModalButtonType,
  CorpModalScreens,
  PolicyTier,
  ModalCategoryType,
  OnboardingModalChoiceProperties,
  ModalNames,
  CorpSessionInfo,
  RewardsAccount,
  CustomerAccountRole,
} from "redmond";
import isEqual from "lodash-es/isEqual";
import {
  CORPORATE_ADMIN_URL,
  ONBOARDING_REFERRER_SOURCE,
  useUserContext,
  VXB,
} from "@capone/common";
import {
  CorporateButton,
  CorporateTypography,
  GenericInfoPopup,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import { useExperimentsById } from "@capone/experiments";
import AdditionalDetails from "./components/AdditionalDetails";
import CreateTraveler from "./components/CreateTraveler";
import ListTravelers from "./components/ListTravelers";
import { ReviewPolicy } from "./components/ReviewPolicy";
import Welcome from "./components/Welcome";
import { trackEvent } from "../../../api/v1/trackEvent";
import { getCorpPassengerList } from "./utils/getCorpPassengerList";
import { updateCorpPassengerList } from "./utils/updateCorpPassengerList";
import classNames from "./styles.module.css";
import { updateUserSeenModal } from "../../../api/v1/user/updateUserSeenModal";
import { WelcomeV2 } from "./components/WelcomeV2";
import { YouAreAllSet } from "./components/YouAreAllSet";

export interface IOnboardingModalProps {
  policies: PolicyTier;
  currentAccount: RewardsAccount | undefined;
}

export const OnboardingModal = ({
  policies,
  currentAccount,
}: IOnboardingModalProps): React.ReactElement => {
  const {
    sessionInfo,
    modalManagerProps: { closeActiveModal, activeModal },
  } = useUserContext("capone-corporate");
  const { matchesMobile } = useDeviceTypes();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [travelers, setTravelers] = useState<IPerson[]>([]);
  const [isLoadingTravelers, setIsLoadingTravelers] = useState(true);
  const [isLoadingSetupBusiness, setIsLoadingSetupBusiness] = useState(false);
  const [isCloseWarningOpen, setIsCloseWarningOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedTraveler, setSelectedTraveler] = useState<
    IPerson | undefined
  >();
  const isOnboardingRevampEnabled =
    useExperimentsById("corp-onboarding-revamp")?.variant === "available";
  const isOnboardingRevampDebugEnabled =
    useExperimentsById("corp-onboarding-revamp")?.variant === "debug";

  const isOnboardingRevampXpEnabled =
    isOnboardingRevampEnabled || isOnboardingRevampDebugEnabled;

  const currentScreenNameForEvent = useMemo(
    () =>
      isOnboardingRevampXpEnabled
        ? {
            0: CorpModalScreens.ONBOARDING_WELCOME,
            1: CorpModalScreens.ONBOARDING_DETAILS,
            2: CorpModalScreens.ONBOARDING_ADDITIONAL,
            3: CorpModalScreens.YOU_ARE_ALL_SET,
          }[step]
        : {
            0: CorpModalScreens.ONBOARDING_WELCOME,
            1: CorpModalScreens.ONBOARDING_LIST,
            2: CorpModalScreens.ONBOARDING_DETAILS,
            3: CorpModalScreens.ONBOARDING_ADDITIONAL,
            4: CorpModalScreens.ONBOARDING_POLICY,
          }[step],
    [step]
  );

  const PROFILE_CREATION_STEP = 2;

  const isPriorBasicProfileCreation = useMemo(
    () => step <= PROFILE_CREATION_STEP,
    [step]
  );

  const closeWarningTitle = isPriorBasicProfileCreation
    ? "Your traveler profile won’t be saved"
    : "Your additional details won’t be saved";

  const closeWarningPrimaryButtonText = isPriorBasicProfileCreation
    ? "Complete traveler profile"
    : "Complete details";

  const isVXB = currentAccount?.productDisplayName === VXB;
  const isPrimary =
    currentAccount?.customerAccountRole === CustomerAccountRole.Primary;
  const isVXBPrimary = isVXB && isPrimary;

  useEffect(() => {
    const open = activeModal === "onboarding";

    if (open && isVXBPrimary && isOnboardingRevampXpEnabled) {
      setStep(1);
    }
    setIsOpen(open);
  }, [isVXBPrimary, activeModal, isOnboardingRevampXpEnabled]);

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          type: "traveler_onboarding",
          screen: currentScreenNameForEvent,
          primary_button: "get_started",
          secondary_button: "skip_to_start_browsing",
          modal_subtitle: "Create your traveler profile",
          modal_title: "Welcome",
          agent_title: "Welcome",
          agent_subtitle: "Create your traveler profile",
          step: `${currentScreenNameForEvent}`,
          funnel: "other",
          category: ModalCategoryType.FEATURE,
        } satisfies ModalAlertProperties,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (sessionInfo && sessionInfo.csrfToken) {
      getCorpPassengerList(sessionInfo).then((corpTravelers) => {
        setTravelers(corpTravelers);
        if (isOnboardingRevampXpEnabled) {
          const existingLoggedUserTraveler = corpTravelers.find(
            (traveler) => traveler.isDefaultProfile
          );
          setSelectedTraveler(existingLoggedUserTraveler);
        }
        setIsLoadingTravelers(false);
      });
    }
  }, [sessionInfo, isOnboardingRevampXpEnabled]);

  const showCloseWarning = useCallback(() => {
    setIsCloseWarningOpen(true);
    trackEvent({
      eventName: MODAL_ALERT,
      properties: {
        type: isOnboardingRevampXpEnabled
          ? "exit_onboarding"
          : "traveler_onboarding_confirmation",
        screen: currentScreenNameForEvent,
        primary_button: closeWarningPrimaryButtonText,
        secondary_button: "leave_without_saving",
        modal_subtitle: "",
        modal_title: closeWarningTitle,
        agent_title: closeWarningTitle,
        agent_subtitle: "",
        step: `${currentScreenNameForEvent}`,
        funnel: "other",
        category: ModalCategoryType.FEATURE,
      } satisfies ModalAlertProperties,
    });
  }, [currentScreenNameForEvent, trackEvent]);

  const hideCloseWarning = useCallback(
    (dispatchEvent = true, buttonChoice = ModalButtonType.PRIMARY) => {
      setIsCloseWarningOpen(false);
      if (dispatchEvent) {
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            type: isOnboardingRevampXpEnabled
              ? "exit_onboarding"
              : "traveler_onboarding_confirmation",
            screen: currentScreenNameForEvent,
            button_choice: buttonChoice,
            primary_button: closeWarningPrimaryButtonText,
            secondary_button: "leave_without_saving",
            modal_subtitle: "",
            modal_title: closeWarningTitle,
            agent_title: closeWarningTitle,
            agent_subtitle: "",
            step: `${currentScreenNameForEvent}`,
            funnel: "other",
            category: ModalCategoryType.FEATURE,
          } satisfies ModalAlertChoiceProperties,
        });
      }
    },
    [currentScreenNameForEvent, trackEvent]
  );

  const close = useCallback(() => {
    hideCloseWarning(true, ModalButtonType.CLOSE);
    updateUserSeenModal({
      modalName: ModalNames.HAS_SEEN_ONBOARDING_MODAL,
    });
    setIsOpen(false);
    closeActiveModal("onboarding");
  }, [hideCloseWarning]);

  const handleSetupBusiness = () => {
    setIsLoadingSetupBusiness(true);
    updateUserSeenModal({
      modalName: ModalNames.HAS_SEEN_ONBOARDING_MODAL,
    }).finally(() => {
      window.location.href = `${CORPORATE_ADMIN_URL}/users?${ONBOARDING_REFERRER_SOURCE}`;
    });
  };

  const skip = useCallback(() => {
    close();
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        type: isOnboardingRevampXpEnabled
          ? "exit_onboarding"
          : "traveler_onboarding_confirmation",
        screen: currentScreenNameForEvent,
        button_choice: ModalButtonType.SECONDARY,
        primary_button: "complete_traveler_profile",
        secondary_button: "leave_without_saving",
        modal_subtitle: "",
        modal_title: closeWarningTitle,
        agent_title: closeWarningTitle,
        agent_subtitle: "",
        step: `${currentScreenNameForEvent}`,
        funnel: "other",
        category: ModalCategoryType.FEATURE,
      } satisfies ModalAlertChoiceProperties,
    });
  }, [close, currentScreenNameForEvent, trackEvent]);

  const nextStep = useCallback(
    (hasChanges?: boolean) => {
      setStep(step + 1);
      if (!isOnboardingRevampXpEnabled) {
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            type: "traveler_onboarding",
            screen: currentScreenNameForEvent,
            button_choice: ModalButtonType.PRIMARY,
            primary_button: step === 0 ? "start" : "next",
            secondary_button: step === 0 ? "skip" : "back",
            has_changes: hasChanges ?? false,
            modal_subtitle: "",
            modal_title: `${currentScreenNameForEvent}`,
            agent_title: `${currentScreenNameForEvent}`,
            agent_subtitle: "",
            step: `${currentScreenNameForEvent}`,
            funnel: "other",
            category: ModalCategoryType.FEATURE,
          } satisfies OnboardingModalChoiceProperties,
        });
      }
    },
    [currentScreenNameForEvent, step, trackEvent]
  );

  const prevStep = useCallback(
    (_event?: React.MouseEvent | null, stepsToGoBack = 1) => {
      if (step > 0) {
        setStep(step - stepsToGoBack);
        if (!isOnboardingRevampXpEnabled) {
          trackEvent({
            eventName: MODAL_ALERT_CHOICE,
            properties: {
              type: "traveler_onboarding",
              screen: currentScreenNameForEvent,
              button_choice: ModalButtonType.SECONDARY,
              primary_button: step === 0 ? "start" : "next",
              secondary_button: step === 0 ? "skip" : "back",
              modal_subtitle: "",
              modal_title: `${currentScreenNameForEvent}`,
              agent_title: `${currentScreenNameForEvent}`,
              agent_subtitle: "",
              step: `${currentScreenNameForEvent}`,
              funnel: "other",
              category: ModalCategoryType.FEATURE,
            } satisfies ModalAlertChoiceProperties,
          });
        }
      }
    },
    [currentScreenNameForEvent, step, trackEvent]
  );

  const onSelectTraveler = useCallback(
    (traveler: IPerson) => {
      setSelectedTraveler(traveler);
    },
    [setSelectedTraveler]
  );

  const createNewTraveler = useCallback(() => {
    setSelectedTraveler(undefined);
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        type: "traveler_onboarding",
        screen: currentScreenNameForEvent,
        button_choice: ModalButtonType.SECONDARY,
        primary_button: "continue",
        secondary_button: "create_new_profile",
        modal_title: "Create your traveler profile",
        agent_title: "Create your traveler profile",
        modal_subtitle: "",
        agent_subtitle: "",
        step: `${currentScreenNameForEvent}`,
        funnel: "other",
        category: ModalCategoryType.FEATURE,
      } satisfies ModalAlertChoiceProperties,
    });
    nextStep();
  }, [currentScreenNameForEvent, nextStep, trackEvent]);

  const removeUndefinedValuesFromTraveler = (traveler: IPerson) => {
    const travelerCopy = { ...traveler };
    Object.keys(travelerCopy).forEach(
      (key: string) =>
        travelerCopy[key as keyof IPerson] === undefined &&
        delete travelerCopy[key as keyof IPerson]
    );

    return travelerCopy;
  };

  const hasMadeChangesToTraveler = useCallback(
    (traveler: IPerson) => {
      const travelerCopy = removeUndefinedValuesFromTraveler(traveler);
      const selectedTravelerCopy = removeUndefinedValuesFromTraveler(
        selectedTraveler!
      );

      return !isEqual(travelerCopy, selectedTravelerCopy);
    },
    [selectedTraveler]
  );

  const handleUpdateTraveler = useCallback(
    (traveler: IPerson): Promise<void> =>
      new Promise<void>((resolve, reject) => {
        updateCorpPassengerList({
          person: traveler,
          currentTravelers: travelers,
          sessionInfo,
          source: "traveler_onboarding",
        })
          .then((updatedTraveler) => {
            setSelectedTraveler(updatedTraveler);
            nextStep(hasMadeChangesToTraveler(updatedTraveler));
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }),
    [hasMadeChangesToTraveler, sessionInfo, travelers, nextStep]
  );

  const goBackFromCreateTraveler = useCallback(() => {
    if (isLoadingTravelers || travelers.length) {
      prevStep(null);
    } else {
      prevStep(null, 2);
    }
  }, [isLoadingTravelers, travelers, prevStep]);

  const steps = useMemo(() => {
    const stepsArray = [
      isOnboardingRevampXpEnabled ? (
        <WelcomeV2
          key="welcome"
          primaryAction={nextStep}
          secondaryAction={handleSetupBusiness}
          isLoading={isLoadingTravelers}
          username={sessionInfo?.userInfo.firstName || ""}
          onClose={close}
          isPrimary={
            (sessionInfo as CorpSessionInfo)?.corporateInfo?.cap1Role === "PR"
          }
          isSecondaryActionLoading={isLoadingSetupBusiness}
          isMobile={matchesMobile}
        />
      ) : (
        <Welcome
          key="welcome"
          primaryAction={nextStep}
          secondaryAction={showCloseWarning}
          isLoading={isLoadingTravelers}
        />
      ),
    ];
    if (!isOnboardingRevampXpEnabled) {
      stepsArray.push(
        <ListTravelers
          key="list"
          primaryAction={createNewTraveler}
          secondaryAction={showCloseWarning}
          onClose={close}
          travelers={travelers}
          isLoadingTravelers={isLoadingTravelers}
          selectedTraveler={selectedTraveler}
          onSelectTraveler={onSelectTraveler}
        />
      );
    }
    stepsArray.push(
      <CreateTraveler
        key="create-profile"
        traveler={selectedTraveler}
        primaryAction={handleUpdateTraveler}
        secondaryAction={goBackFromCreateTraveler}
        onClose={showCloseWarning}
        isOnboardingRevampEnabled={isOnboardingRevampXpEnabled}
        isMobile={matchesMobile}
      />,
      <AdditionalDetails
        key="additional-details"
        traveler={selectedTraveler!}
        primaryAction={handleUpdateTraveler}
        secondaryAction={prevStep}
        onClose={skip}
        isOnboardingRevampEnabled={isOnboardingRevampXpEnabled}
        isMobile={matchesMobile}
      />
    );
    if (!isOnboardingRevampXpEnabled) {
      stepsArray.push(
        <ReviewPolicy
          key="review-policy"
          policies={policies}
          productType="flight"
          primaryAction={close}
          secondaryAction={prevStep}
          onClose={close}
        />
      );
    }
    if (isOnboardingRevampXpEnabled) {
      stepsArray.push(
        <YouAreAllSet
          key="you-are-all-set"
          primaryAction={close}
          secondaryAction={prevStep}
          onClose={close}
        />
      );
    }

    return stepsArray;
  }, [
    nextStep,
    showCloseWarning,
    createNewTraveler,
    travelers,
    isLoadingTravelers,
    selectedTraveler,
    onSelectTraveler,
    handleUpdateTraveler,
    goBackFromCreateTraveler,
    prevStep,
    policies,
    close,
    isOnboardingRevampXpEnabled,
    isLoadingSetupBusiness,
  ]);

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="onboarding-title onboarding-subtitle"
        aria-describedby="onboarding-description"
        data-testid="corporate-onboarding-modal"
        onClose={showCloseWarning}
        maxWidth={!matchesMobile ? "lg" : undefined}
        fullWidth={matchesMobile}
        className={classNames.modal}
        scroll="paper"
        key={`onboarding-step-${step}`}
        PaperProps={{
          style: matchesMobile
            ? {
                width: "90%",
                maxWidth:
                  windowWidth <= 320
                    ? "320px"
                    : windowWidth <= 360
                    ? "360px"
                    : windowWidth <= 390
                    ? "390px"
                    : "428px",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                maxHeight: "85vh",
              }
            : undefined,
        }}
      >
        <Box className={clsx(classNames.stepContainer, `step-${step}`)}>
          {steps[step]}
        </Box>
      </Dialog>
      {/* note: we need to conditionally reender this because of a race condition of when this modal closes and when the onboarding modal closes that doesn't reset the page overflow correctly */}
      {isOpen && (
        <GenericInfoPopup
          className={classNames.closeWarning}
          isMobile={matchesMobile}
          open={isCloseWarningOpen}
          showCloseButton
          disableEnforceFocus
          onClose={hideCloseWarning}
          subtitle={
            isPriorBasicProfileCreation ? (
              <>
                <CorporateTypography variant="subtitle2" fontWeight="light">
                  Complete your traveler profile now to automatically apply your
                  information and loyalty programs during booking.
                </CorporateTypography>
                <br />
                <CorporateTypography variant="subtitle2" fontWeight="light">
                  Not quite ready? You can always add profiles later under the
                  Traveler Profile section or during checkout.
                </CorporateTypography>
              </>
            ) : (
              <CorporateTypography variant="subtitle2" fontWeight="light">
                Not quite ready? You can always add information to your profile
                later under the Traveler Profile section or during checkout.
              </CorporateTypography>
            )
          }
          title={
            isOnboardingRevampXpEnabled ? (
              <CorporateTypography
                variant={matchesMobile ? "h2" : "h4"}
                fontWeight="light"
              >
                {closeWarningTitle}
              </CorporateTypography>
            ) : (
              closeWarningTitle
            )
          }
          image={<Icon className="error-icon" name={IconName.Caution} />}
          buttons={
            isOnboardingRevampXpEnabled ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: matchesMobile ? "column" : "row",
                  width: matchesMobile ? "100%" : "auto",
                  gap: matchesMobile ? "16px" : "20px",
                }}
              >
                {matchesMobile && (
                  <CorporateButton
                    size="large"
                    onClick={() => hideCloseWarning()}
                    fullWidth
                  >
                    {closeWarningPrimaryButtonText}
                  </CorporateButton>
                )}
                <CorporateButton
                  size={matchesMobile ? "large" : "medium"}
                  variant="outlined"
                  onClick={skip}
                  fullWidth={matchesMobile}
                >
                  Leave without saving
                </CorporateButton>
                {!matchesMobile && (
                  <CorporateButton
                    size="medium"
                    onClick={() => hideCloseWarning()}
                  >
                    {closeWarningPrimaryButtonText}
                  </CorporateButton>
                )}
              </Box>
            ) : (
              [
                {
                  buttonText: "Leave without saving",
                  defaultStyle: "h4r-secondary",
                  onClick: skip,
                },
                {
                  buttonText: closeWarningPrimaryButtonText,
                  onClick: hideCloseWarning,
                },
              ]
            )
          }
        />
      )}
    </>
  );
};
