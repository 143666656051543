import { isCorpTenant, useUserContext } from "@capone/common";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import {
  CorporateTypography,
  PagedCardCarousel,
  useDeviceTypes,
} from "halifax";
import React, { useMemo, useState } from "react";
import { InView } from "react-intersection-observer";
import { CorpSessionInfo } from "redmond";
import { config } from "../../../api/config";
import { trackEvent } from "../../../api/v1/trackEvent";
import {
  getExperimentVariant,
  useExperiments,
} from "../../../context/experiments";
import VALUE_PROP_CARDS, { ImagePosition } from "./constants";
import "./styles.scss";

const ValuePropCard = ({
  imageUrl,
  imagePosition,
  description,
  matchesMobile,
}: {
  imageUrl: string;
  imagePosition?: ImagePosition;
  description: JSX.Element;
  matchesMobile: boolean;
}) => {
  const dynamicImagePosition = matchesMobile
    ? imagePosition?.mobile
    : imagePosition?.desktop;

  return (
    <Box className="value-prop-card">
      <Box
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPositionX: dynamicImagePosition || `center`,
        }}
        className="value-prop-image"
      />
      <CorporateTypography
        variant={matchesMobile ? "body1" : "h5"}
        fontWeight={matchesMobile ? "regular" : "light"}
      >
        {description}
      </CorporateTypography>
    </Box>
  );
};

export const ValuePropCarousel = () => {
  const isCorporate = isCorpTenant(config.TENANT);
  const expState = useExperiments();
  const isCorpOnboardingRevampEnabled =
    getExperimentVariant(expState.experiments, "corp-onboarding-revamp") ===
      "available" ||
    getExperimentVariant(expState.experiments, "corp-onboarding-revamp") ===
      "debug";
  if (!isCorporate || !isCorpOnboardingRevampEnabled) {
    return null;
  }
  const { matchesMobile, matchesDesktop, matchesLargeDesktop, matchesTablet } =
    useDeviceTypes();
  const { sessionInfo } = useUserContext("capone-corporate");
  const isPrimary =
    (sessionInfo as CorpSessionInfo)?.corporateInfo?.cap1Role === "PR";

  const [viewedTracked, setViewedTracked] = useState(false);
  const trackedPages = useMemo(() => new Set<string>(), []);

  const cardItems = useMemo(
    () => VALUE_PROP_CARDS.filter((card) => isPrimary || !card.isPrimary),
    [isPrimary]
  );

  return (
    <Box
      data-testid="corp-value-prop-carousel"
      className={clsx("value-prop-carousel-root", {
        mobile: matchesMobile,
        smallScreen: (matchesDesktop && !matchesLargeDesktop) || matchesTablet,
      })}
    >
      <CorporateTypography
        variant={matchesMobile ? "h4" : "h2"}
        fontWeight={matchesMobile ? "regular" : "light"}
        className="title"
      >
        {isPrimary
          ? "Access powerful tools and benefits for your business"
          : "Booking business travel doesn’t have to feel like work"}
      </CorporateTypography>
      <Box className="value-prop-carousel-wrapper">
        <InView
          as="div"
          onChange={(inView) => {
            if (inView && !viewedTracked) {
              trackEvent({
                eventName: "viewed_home_page_value_prop_carousel",
                properties: {
                  pages: cardItems.map((c) => c.key),
                },
              });
              setViewedTracked(true);
            }
          }}
          threshold={1}
        >
          <PagedCardCarousel
            itemsPerPage={matchesMobile ? 2 : 3}
            pageClassName="value-prop-carousel-page"
            cardContents={cardItems.map((item) => (
              <ValuePropCard
                key={item.key}
                imageUrl={item.imageUrl}
                imagePosition={item.imagePosition}
                matchesMobile={matchesMobile}
                description={item.description}
              />
            ))}
            trackingData={cardItems.map((item) => ({
              type: item.key,
            }))}
            onPageViewed={(pageKey, tracking) => {
              if (!trackedPages.has(pageKey)) {
                trackEvent({
                  eventName: "viewed_home_page_value_props",
                  properties: {
                    page_items: tracking.map((t) => t.type),
                  },
                });
                trackedPages.add(pageKey);
              }
            }}
          />
        </InView>
      </Box>
    </Box>
  );
};

export default ValuePropCarousel;
