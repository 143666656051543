import React, { useEffect, useState, useMemo } from "react";
import { Route, useHistory } from "react-router-dom";
import {
  B2B_PORTAL_AUTH_REDIRECT_TO,
  B2B_PORTAL_UNAUTHORIZED_PATH,
  CallState,
} from "redmond";
import {
  isCorpTenant,
  SESSION_APPROVAL_PROCESS_KEY,
  useUserContext,
} from "@capone/common";
import { fetchUserPolicies } from "b2b-base/src/api/v1/policies/fetchPolicies";
import { fetchUserInfo } from "../../../api/v1/fetchUserInfo";
import {
  getExperimentVariant,
  useExperiments,
} from "../../../context/experiments";
import { UNPROTECTED_PATHS } from "../../../utils/urlPaths";
import { useMedalliaFeedback } from "../../MedalliaFeedback";
import fetchBusinessPortalAuthorized from "../../../api/v1/portal-access/fetchBusinessPortalAuthorized";
import config from "../../../utils/config";

interface IProtectedRouteProps {
  protectedUrl: string;
  protectedContent: (pathname: string) => JSX.Element;
  unprotectedContent: (notAuthenticated: boolean) => JSX.Element;
}

const ProtectedRoute = (props: IProtectedRouteProps) => {
  const { protectedUrl, protectedContent, unprotectedContent } = props;
  const [authenticated, setAuthenticated] = useState(false);
  const [displayUnathenticatedPage, setDisplayUnathenticatePage] =
    useState(false);

  const expState = useExperiments();

  const isApprovalsV2Enabled = useMemo(
    () =>
      getExperimentVariant(expState.experiments, "corp-approvals-v2") === "m2",
    [expState.experiments]
  );

  const {
    updateSessionInfo,
    updateBusinessEligibility,
    setPolicies,
    setEligibleBusinessAccounts,
    setBusinesses,
  } = useUserContext(config.TENANT);
  const history = useHistory();

  const isCorporate = isCorpTenant(config.TENANT);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userInfoResponse = await fetchUserInfo<typeof config.TENANT>();
        if (updateSessionInfo) {
          updateSessionInfo(userInfoResponse);
        }
      } catch (err) {
        if (UNPROTECTED_PATHS.includes(window.location.pathname)) {
          setDisplayUnathenticatePage(true);
        } else {
          const redirectUrl = `${window.location.pathname}${window.location.search}`;
          if (!redirectUrl.includes("/auth/")) {
            sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, redirectUrl);
          }
          history.push(B2B_PORTAL_UNAUTHORIZED_PATH);
        }
      }
    };
    if (!authenticated) fetchUser();
  }, [updateSessionInfo, authenticated]);

  useEffect(() => {
    if (
      expState.callState !== CallState.InProcess &&
      expState.callState !== CallState.NotCalled
    ) {
      setAuthenticated(true);
    }
  }, [expState]);

  useEffect(() => {
    if (!isCorporate) {
      if (authenticated) {
        const setBusinessEligibility = async function () {
          if (updateBusinessEligibility && setEligibleBusinessAccounts) {
            const eligibleAccountsResp = await fetchBusinessPortalAuthorized();
            if (eligibleAccountsResp.isAuthorized) {
              const eligibleAccounts = eligibleAccountsResp.rewardsAccounts;
              const { businesses } = eligibleAccountsResp;
              setEligibleBusinessAccounts(eligibleAccounts ?? []);
              setBusinesses(businesses);
              updateBusinessEligibility(eligibleAccounts.length > 0);
            }
          }
        };
        setBusinessEligibility();
      }
    }
  }, [authenticated, updateBusinessEligibility]);

  useEffect(() => {
    const fetchPolicy = async () => {
      if (setPolicies) {
        try {
          const userPolicies = await fetchUserPolicies();
          setPolicies(userPolicies.policyDetail);

          let approvalProcess = "auto";

          if (userPolicies.policyDetail.settings.isApprovalRequired === true) {
            approvalProcess = isApprovalsV2Enabled ? "24hr_review" : "manual";
          }

          sessionStorage.setItem(SESSION_APPROVAL_PROCESS_KEY, approvalProcess);
        } catch (error) {
          console.error(error);
          sessionStorage.setItem(SESSION_APPROVAL_PROCESS_KEY, "auto");
        }
      }
    };
    isCorporate && expState.experiments.length > 0 && fetchPolicy();
  }, [setPolicies, isCorporate, expState.experiments]);

  useMedalliaFeedback();

  return (
    <Route
      path={protectedUrl}
      render={(prop) =>
        authenticated
          ? protectedContent(prop.history.location.pathname)
          : displayUnathenticatedPage
          ? unprotectedContent(!authenticated)
          : null
      }
    />
  );
};

export default ProtectedRoute;
