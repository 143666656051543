import axios from "axios";
import { fetchAreModalsEligiblePrefix } from "../../paths";
import { config } from "../../config";
import { ModalName, notSeenModals } from "redmond/common/interfaces";

export interface AreModalsEligibleRequest {
  modalNames: ModalName[];
}
export interface AreModalsEligibleResponse {
  isModalEligible: Record<ModalName, boolean>;
}

export const fetchAreModalsEligible = async (
  modalNames: ModalName[]
): Promise<Record<ModalName, boolean>> => {
  try {
    const body = { modalNames: modalNames } as AreModalsEligibleRequest;
    const res = await axios.post(fetchAreModalsEligiblePrefix, body, {
      baseURL: config.baseURL,
    });
    const responseBody: AreModalsEligibleResponse = res.data;
    return responseBody.isModalEligible;
  } catch (e) {
    return notSeenModals;
  }
};
