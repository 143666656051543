// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0QXKkz9NIlV4V0hF2N9HiQ\\=\\= {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n\n.ptBaseModule-MuiTypography-h4.Wmp6Zwiv4mQIv-VNmeKzWw\\=\\= {\n  font-size: 16px;\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./../b2b-base/src/components/capone-corporate/OnboardingModal/components/ListTravelers/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".travelersList {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n\n:global(.ptBaseModule-MuiTypography-h4).listTitle {\n  font-size: 16px;\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"travelersList": "_0QXKkz9NIlV4V0hF2N9HiQ==",
	"listTitle": "Wmp6Zwiv4mQIv-VNmeKzWw=="
};
export default ___CSS_LOADER_EXPORT___;
