import { CorpBusinessAccount } from "redmond";

export const sortBusinessAccountsByOptIn = (
  businessAccounts: CorpBusinessAccount[]
) => {
  const [optedInAccounts, nonOptedInAccounts] = businessAccounts.reduce(
    (result, account) => {
      if (account.optedIn) {
        result[0].push(account);
      } else {
        result[1].push(account);
      }
      return result;
    },
    [[] as CorpBusinessAccount[], [] as CorpBusinessAccount[]]
  );

  return { optedInAccounts, nonOptedInAccounts };
};
