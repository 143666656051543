import createTheme from "@material-ui/core/styles/createTheme";
import { PRIMARY_TEXT_GREY } from "../styleConstants";
import { colors } from "../utils/capone-corporate/colors";

export const corpTheme = {
  name: "capone-corporate",
  theme: createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#0276B1",
        dark: "#026597",
        light: "#4198C4",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#013D58",
        dark: "#10253F",
        light: "#004E72",
        contrastText: "#FFFFFF",
      },
      error: {
        main: "#CC2427",
        dark: "#BA2629",
        light: "#D95B5D",
        contrastText: "#FFFFFF",
      },
      warning: {
        main: "#F9C606",
        dark: "#E8AD11",
        light: "#FBD751",
        contrastText: "#FFFFFF",
      },
      info: {
        main: "#013D58",
        dark: "#10253F",
        light: "#004E72",
        contrastText: "#FFFFFF",
      },
      success: {
        main: "#25810E",
        dark: "#1E6A0B",
        light: "#59A545",
        contrastText: "#FFFFFF",
      },
      action: {
        active: "#676868",
        hover: "#F4F4F4",
        selected: "#E4E5E5",
        disabled: "#878787",
        disabledBackground: "#F4F4F4",
        focus: "#F4F4F4",
      },
      text: {
        primary: "#141414",
        secondary: "#676868",
        disabled: "#878787",
      },
    },
    typography: {
      fontFamily: "Optimist",
      htmlFontSize: 14,
      fontSize: 14,
      h1: {
        fontSize: 36,
        fontFamily: "Optimist",
        letterSpacing: 0,
      },
      h2: {
        fontSize: 24,
        fontFamily: "Optimist",
        letterSpacing: 0,
        fontWeight: 400,
      },
      h3: {
        fontFamily: "Optimist",
        fontSize: 22,
        letterSpacing: 0,
      },
      h4: {
        fontFamily: "Optimist",
        fontSize: 18,
        color: colors["primary-text-grey"],
        letterSpacing: 0,
      },
      h5: {
        fontFamily: "Optimist",
        fontSize: 18,
        color: colors["secondary-text-grey"],
        letterSpacing: 0,
      },
      h6: {
        fontFamily: "Optimist",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
      },
      body1: {
        fontFamily: "Optimist",
        fontSize: 14,
        color: colors["primary-text-grey"],
        letterSpacing: 0,
      },
      body2: {
        fontFamily: "Optimist",
        fontSize: 14,
        color: colors["secondary-text-grey"],
        letterSpacing: 0,
      },
      caption: {
        fontFamily: "Optimist",
        fontSize: 12,
        color: colors["secondary-text-grey"],
        letterSpacing: 0,
      },
      overline: {
        fontFamily: "Optimist",
        fontSize: 14,
        letterSpacing: 0,
      },
      subtitle1: {
        fontFamily: "Optimist",
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 0,
      },
      subtitle2: {
        fontFamily: "Optimist",
        fontSize: 12,
        letterSpacing: 0,
      },
      button: {
        fontFamily: "Optimist",
        fontSize: 14,
        textTransform: "none",
        letterSpacing: 0,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiLink: {
        root: {
          color: PRIMARY_TEXT_GREY,
        },
        underlineHover: {
          "&:hover": {
            "text-decoration": "none",
          },
        },
      },
      MuiRadio: {
        colorPrimary: {
          "&$checked": {
            color: colors["blue-9"],
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&:focus": {
            outline: "none",
          },
          "&:focus-visible": {
            outline: "2px solid #002638",
            outlineOffset: "2px",
          },
        },
      },
      MuiButton: {
        root: {
          "&:focus-visible": {
            outline: "2px solid #002638",
            outlineOffset: "2px",
          },
        },
      },
      MuiDialog: {
        paperWidthXs: {
          maxWidth: "500px",
        },
        paperWidthSm: {
          maxWidth: "700px",
        },
        paperWidthMd: {
          maxWidth: "800px",
        },
        paperWidthLg: {
          maxWidth: "950px",
        },
        paperWidthXl: {
          maxWidth: "1050px",
        },
      },
    },
  }),
};
