import React from "react";

export const TITLE = (
  <>
    Thousands of <strong>rental cars</strong> to choose from.
  </>
);

export const PARADISE_TITLE = "Your next journey starts here.";

export const EARN_ENHANCEMENT_TITLE = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return (
    <span className="font-regular">
      Earn{" "}
      <strong>
        {earnRate}
        {multiplier} {currency}{" "}
      </strong>{" "}
      on cars with your {productDisplayName} account.
    </span>
  );
};

export const SUBTITLE =
  "Book with flexibility and enjoy elevated travel benefits.";
