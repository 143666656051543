import React from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  CloseButton,
  Icon,
  IconName,
  InfoBox,
  renderFDABulletList,
  renderFDAInfoBoxOptionOne,
  renderFDAInfoBoxOptionTwo,
} from "halifax";
import {
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_CHIP_LABEL,
  FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_CANCEL_CTA_LABEL,
  FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_NOTICE,
  FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_SUBTITLE,
  FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_TITLE,
  FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_OK_CTA_LABEL,
  FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_SUBTITLE,
  FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_TITLE,
  INFO_BOX_PRIMARY_TITLE,
  INFO_BOX_SECONDARY_TITLE,
} from "../Header/components/constants";

import "./styles.scss";

export interface ValuePropModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmCtaClick: () => void;
  onCancelCtaClick: () => void;
}

export const ValuePropModal: React.FC<ValuePropModalProps> = ({
  open,
  onClose,
  onConfirmCtaClick,
  onCancelCtaClick,
}) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          padding: 0,
          borderRadius: 4,
          overflow: "hidden",
          maxWidth: "800px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: theme.palette.secondary.dark,
          color: "white",
          paddingTop: "50px",
          paddingBottom: "20px",
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <CloseButton onClose={onClose} style={{ color: "white" }} />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Icon name={IconName.BudgetIcon} className="disruption-circle-blue" />
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontFamily: "Premium Serif",
                fontSize: "30px",
                fontWeight: 400,
                color: "white",
                flexGrow: 1,
              }}
            >
              {FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_TITLE}
            </Typography>
            <Typography
              style={{
                display: "inline-block",
                backgroundColor: theme.palette.success.light,
                borderRadius: 8,
                paddingLeft: 12,
                paddingRight: 12,
                fontSize: "14px",
                fontWeight: 600,
                color: "white",
                whiteSpace: "nowrap",
                textAlign: "center",
              }}
            >
              {FINTECH_SUBSCRIPTION_V3_SNACKBAR_CHIP_LABEL}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "14px",
            paddingRight: "30px",
            paddingLeft: "30px",
            whiteSpace: "pre-line",
          }}
        >
          {FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_SUBTITLE}
        </Typography>
      </DialogTitle>

      <DialogContent
        className={clsx("value-prop-dialog-content")}
        style={{ padding: 32 }}
      >
        <Box style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
          <Icon name={IconName.DisruptionProtectionCircleBlue} />
          <Box style={{ marginLeft: "15px" }}>
            <Typography
              variant="h3"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                color: theme.palette.secondary.main,
              }}
            >
              {FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_TITLE}
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: theme.palette.text.primary,
              }}
            >
              {FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_SUBTITLE}
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            backgroundColor: "#F9C6061F",
            padding: 8,
            borderRadius: 16,
          }}
        >
          <Box style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Icon name={IconName.LightBulb} />
            <Typography
              variant="body2"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "18px",
                color: theme.palette.text.primary,
              }}
            >
              {FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_NOTICE}
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            gap: 16,
            alignItems: "stretch",
            marginTop: "30px",
            paddingRight: "15px",
          }}
        >
          <Box
            style={{
              flex: "0 0 35%",
              display: "flex",
            }}
          >
            <InfoBox
              title={INFO_BOX_PRIMARY_TITLE}
              bodySections={[
                <div className="info-box-item-primary">
                  {renderFDABulletList({ delayThresholdHours: "3" })}
                </div>,
              ]}
              mobile={false}
            />
          </Box>
          <Box
            style={{
              flex: "0 0 65%",
              display: "flex",
            }}
          >
            <InfoBox
              mobile={false}
              title={INFO_BOX_SECONDARY_TITLE}
              bodySections={[
                renderFDAInfoBoxOptionOne(),
                renderFDAInfoBoxOptionTwo(),
              ]}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 16,
          padding: 24,
        }}
      >
        <Button
          variant="contained"
          style={{
            width: "100%",
            maxWidth: 450,
            textTransform: "none",
            fontWeight: "normal",
            fontSize: "16px",
            backgroundColor: theme.palette.primary.main,
            color: "white",
          }}
          onClick={onConfirmCtaClick}
        >
          {FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_OK_CTA_LABEL}
        </Button>
        <Button
          variant="outlined"
          style={{
            width: "100%",
            maxWidth: 450,
            textTransform: "none",
            fontWeight: "normal",
            fontSize: "16px",
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            marginLeft: 0,
          }}
          onClick={onCancelCtaClick}
        >
          {FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_CANCEL_CTA_LABEL}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
