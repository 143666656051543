import dayjs from "dayjs";
import {
  RecentCarSearch,
  RecentFlightSearch,
  RecentHotelSearch,
  RecentlyViewedHotel,
  RecentPackageSearch,
  RewardsAccount,
} from "redmond";
import { fetchRecentlySearchedCars } from "../../api/v1/recently-viewed-and-searched/fetchRecentlySearchedCars";
import { fetchRecentlySearchedFlights } from "../../api/v1/recently-viewed-and-searched/fetchRecentlySearchedFlights";
import { fetchRecentlySearchedHotels } from "../../api/v1/recently-viewed-and-searched/fetchRecentlySearchedHotels";
import { fetchRecentlyViewedHotels } from "../../api/v1/recently-viewed-and-searched/fetchRecentlyViewedHotels";
import {
  RECENTLY_VIEWED_DESKTOP_P0_V1,
  RECENTLY_VIEWED_DESKTOP_P0_V2,
  RECENTLY_VIEWED_DESKTOP_P0_V3,
  RECENTLY_VIEWED_DESKTOP_P0_V4,
  RecentlyViewedDesktopP0PropVariantType,
} from "../../context/experiments";
import { fetchRecentlySearchedPackages } from "../../api/v1/recently-viewed-and-searched/fetchRecentlySearchedPackages";

export interface RecentlyViewedAndSearchedProps {
  recentSearchesAndViews?: (
    | RecentHotelSearch
    | RecentlyViewedHotel
    | RecentFlightSearch
    | RecentCarSearch
    | RecentPackageSearch
  )[];
  largestValueAccount?: RewardsAccount;
  latency?: number;
  negativeMargin?: boolean;
  showSkeleton?: boolean;
  loading: boolean;
}

export const fetchRecentlyViewedAndSearched = async (
  largestValueAccount: RewardsAccount,
  variant: RecentlyViewedDesktopP0PropVariantType,
  fetchFlightsRecentlySearched: boolean,
  fetchCarsRecentlySearched: boolean,
  fetchPackagesRecentlySearched: boolean
): Promise<RecentlyViewedAndSearchedProps | false> => {
  const startTime = dayjs();
  let recentSearches: (
    | RecentHotelSearch
    | RecentFlightSearch
    | RecentCarSearch
    | RecentPackageSearch
  )[] = [];
  const recentViews: RecentlyViewedHotel[] = [];

  try {
    const recentlySearchedHotelsResponse = await fetchRecentlySearchedHotels();
    localStorage.setItem(
      "recently_searched_hotels",
      JSON.stringify(recentlySearchedHotelsResponse?.searches || [])
    );
    const event = new Event("update_recently_searched_hotels");
    dispatchEvent(event);
    recentSearches.push(...recentlySearchedHotelsResponse.searches);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  try {
    const recentlyViewedResponse = await fetchRecentlyViewedHotels();
    recentViews.push(...recentlyViewedResponse.recentlyViewedLodgings);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  if (fetchPackagesRecentlySearched) {
    try {
      const recentlySearchedPackagesResponse =
        await fetchRecentlySearchedPackages();
      localStorage.setItem(
        "recently_searched_packages",
        JSON.stringify(recentlySearchedPackagesResponse?.searches || [])
      );
      const event = new Event("update_recently_searched_packages");
      dispatchEvent(event);
      recentSearches.push(...recentlySearchedPackagesResponse.searches);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  if (fetchFlightsRecentlySearched) {
    try {
      const recentlySearchedFlightsResponse =
        await fetchRecentlySearchedFlights();

      localStorage.setItem(
        "recently_searched_flights",
        JSON.stringify(recentlySearchedFlightsResponse?.searches || [])
      );
      const event = new Event("update_recently_searched_flights");
      dispatchEvent(event);
      recentSearches.push(...recentlySearchedFlightsResponse.searches);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  if (fetchCarsRecentlySearched) {
    try {
      const recentlySearchedCarsResponse = await fetchRecentlySearchedCars();
      localStorage.setItem(
        "recently_searched_cars",
        JSON.stringify(recentlySearchedCarsResponse?.searches || [])
      );
      const event = new Event("update_recently_searched_cars");
      dispatchEvent(event);
      recentSearches.push(...(recentlySearchedCarsResponse?.searches ?? []));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  if (
    variant === RECENTLY_VIEWED_DESKTOP_P0_V1 ||
    variant === RECENTLY_VIEWED_DESKTOP_P0_V3
  ) {
    recentSearches = recentSearches.sort(
      (a, b) =>
        new Date(b.searchDate).getTime() - new Date(a.searchDate).getTime()
    );
  }

  let recentSearchesAndViews: (
    | RecentHotelSearch
    | RecentlyViewedHotel
    | RecentFlightSearch
    | RecentCarSearch
    | RecentPackageSearch
  )[] = [...recentSearches, ...recentViews];

  if (
    variant === RECENTLY_VIEWED_DESKTOP_P0_V4 ||
    variant === RECENTLY_VIEWED_DESKTOP_P0_V2
  ) {
    recentSearchesAndViews = recentSearchesAndViews.sort((a, b) => {
      const ADateToCompare =
        (a as RecentFlightSearch).departureDate ??
        (a as RecentCarSearch).pickUpDate ??
        (a as RecentHotelSearch).checkInDate;

      const BDateToCompare =
        (b as RecentFlightSearch).departureDate ??
        (b as RecentCarSearch).pickUpDate ??
        (b as RecentHotelSearch).checkInDate;

      return (
        new Date(ADateToCompare).getTime() - new Date(BDateToCompare).getTime()
      );
    });
  }

  recentSearchesAndViews =
    recentSearchesAndViews.length > 8
      ? recentSearchesAndViews.slice(0, 8)
      : recentSearchesAndViews;

  const latency = dayjs().diff(startTime, "seconds", true);
  if (recentSearchesAndViews.length > 0) {
    return {
      recentSearchesAndViews,
      largestValueAccount,
      latency,
      loading: false,
    };
  }
  return false;
};
