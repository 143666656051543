import axios from "axios";
import { config, recentlyViewedAxiosCancelToken } from "../../config";

import { ILocationQueryLabel, IResponse } from "redmond";
import { locationAutocompleteApiPrefix } from "../../paths";

export const fetchLocationAutocomplete = (
  body: ILocationQueryLabel
): Promise<IResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        locationAutocompleteApiPrefix,
        {
          query: { ...body, l: body.l || "" },
        },
        {
          baseURL: config.baseURL,
          cancelToken: recentlyViewedAxiosCancelToken.token,
        }
      );
      if (res.status === 499) {
        throw new Error();
      } else {
        resolve(res.data);
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        // do not reject if the error is a cancellation from the cancel token
        return;
      }
      reject(e);
    }
  });
