/* eslint-disable import/prefer-default-export */
import React, { lazy, PropsWithChildren, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import {
  AVAILABLE,
  CORE_CARS,
  getExperimentVariant,
  useExperiments,
} from "../../../context/experiments";

const CaponePortal = lazy(() => import("../Portal"));

export const BaseProviders: React.FC<PropsWithChildren> = ({ children }) => {
  const expState = useExperiments();
  const loadCoreProviders = getExperimentVariant(
    expState.experiments,
    CORE_CARS
  ).startsWith(AVAILABLE);

  return loadCoreProviders ? (
    <Suspense fallback="loading">
      <CaponePortal>{children}</CaponePortal>
    </Suspense>
  ) : (
    <BrowserRouter>
      <CompatRouter>{children}</CompatRouter>
    </BrowserRouter>
  );
};
