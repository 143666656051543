/* eslint-disable import/prefer-default-export */
import {
  CORPORATE_ROLE_ADMIN,
  HAS_CHOSEN_ACCOUNT,
  ReferrerSource,
  REFERRER_SOURCE,
  VXB,
} from "@capone/common";
import { useExperimentIsVariant, useExperiments } from "@capone/experiments";
import { useDeviceTypes } from "halifax";
import { useState, useEffect } from "react";
import {
  CorpBusinessAccount,
  CorporateInfo,
  ModalNames,
  ModalType,
  RewardsAccount,
} from "redmond";
import fetchCorpFintechEligibility from "b2b-base/src/api/v1/cotbFintech/fetchCorpFintechEligibility";
import { fetchAreModalsEligible } from "b2b-base/src/api/v1/user/fetchAreModalsEligible";
import { sortBusinessAccountsByOptIn } from "../../MultiAccount/utils";

const MODAL_PRIORITY: ModalType[] = [
  "multiaccount",
  "onboarding",
  "vxbHelptip",
  "fintechv3",
  "postBookingFDA",
  "seanEllis",
];

const initialModalState = {
  multiaccount: null,
  onboarding: null,
  vxbHelptip: null,
  fintechv3: null,
  // TODO: add postBookingFDA conditions to active modal manager
  postBookingFDA: false,
  seanEllis: null,
};

type ModalState = Record<ModalType, boolean | null>;

export const useActiveModalManager = (
  corporateInfo: CorporateInfo | undefined,
  currentAccount: RewardsAccount | undefined,
  businessAccounts: CorpBusinessAccount[]
) => {
  const [activeModal, setActiveModal] = useState<ModalType | null>(() => null);
  const [modalConditions, setModalConditions] =
    useState<ModalState>(initialModalState);

  const { matchesMobile } = useDeviceTypes();

  const { experimentsCallState } = useExperiments();

  const isOnboardingRevampEnabled = useExperimentIsVariant(
    "corp-onboarding-revamp",
    "available"
  );

  const isOnboardingRevampDebugEnabled = useExperimentIsVariant(
    "corp-onboarding-revamp",
    "debug"
  );

  const isFintechSubscriptionV3Enabled = useExperimentIsVariant(
    "corp-fintech-subscription-smoketest",
    "available_variant3"
  );

  const isSeanEllisQuestionEnabled = useExperimentIsVariant(
    "corp-sean-ellis-question",
    "available"
  );

  const isSeanEllisQuestionDebugEnabled = useExperimentIsVariant(
    "corp-sean-ellis-question",
    "debug"
  );

  const toggleModal = (modal: ModalType, condition: boolean) =>
    setModalConditions((prev) => ({ ...prev, [modal]: condition }));

  // note: determine multiaccount modal eligibility
  useEffect(() => {
    if (!businessAccounts.length) {
      return;
    }

    const referrerSource = new URLSearchParams(window.location?.search).get(
      REFERRER_SOURCE
    );

    const isAccountPreSelected =
      referrerSource === ReferrerSource.VxbAcknowledgement ||
      referrerSource === ReferrerSource.AdminPortal;

    if (isAccountPreSelected) {
      toggleModal("multiaccount", false);
      return;
    }

    const { optedInAccounts, nonOptedInAccounts } =
      sortBusinessAccountsByOptIn(businessAccounts);

    const modalNonDeclinedAccounts = nonOptedInAccounts.filter(
      (account) => !account.declineStatus
    );

    const accountsToDisplay =
      modalNonDeclinedAccounts.length + optedInAccounts.length;

    const showMultiAccountModal =
      sessionStorage.getItem(HAS_CHOSEN_ACCOUNT) !== "true" &&
      accountsToDisplay > 1;

    toggleModal("multiaccount", showMultiAccountModal);
  }, [businessAccounts]);

  // note: determine onboarding modal and vxb helptip eligibility
  useEffect(() => {
    if (!corporateInfo) {
      return;
    }

    const hasSeenOnboardingModal =
      corporateInfo.hasSeenModalMap?.[ModalNames.HAS_SEEN_ONBOARDING_MODAL];

    const showOnboardingModal =
      isOnboardingRevampDebugEnabled ||
      (!hasSeenOnboardingModal && !matchesMobile);

    const isVXB = currentAccount?.productDisplayName === VXB;

    const showHelpTip =
      isVXB &&
      corporateInfo.role.includes(CORPORATE_ROLE_ADMIN) &&
      !corporateInfo.hasSeenModalMap?.[ModalNames.VXB_HELPTIP];

    setModalConditions((prev) => ({
      ...prev,
      onboarding: showOnboardingModal,
      vxbHelptip: showHelpTip,
    }));
  }, [
    corporateInfo,
    isOnboardingRevampEnabled,
    isOnboardingRevampDebugEnabled,
    currentAccount,
    matchesMobile,
  ]);

  // note: determine fintech subscription V3 modal eligibility
  useEffect(() => {
    if (!corporateInfo) {
      return;
    }

    if (!isFintechSubscriptionV3Enabled) {
      toggleModal("fintechv3", false);
      return;
    }

    fetchCorpFintechEligibility({
      businessId: corporateInfo.businessId ?? "",
    }).then((response) => {
      const isFintechV3Eligible = response.isEligibleToOptin;
      toggleModal("fintechv3", isFintechV3Eligible);
    });
  }, [corporateInfo, isFintechSubscriptionV3Enabled]);

  // note: determine Sean Ellis question modal eligibility
  useEffect(() => {
    if (!corporateInfo) {
      return;
    }

    const hasSeenSeanEllisQuestion = corporateInfo.hasSeenModalMap?.sean_ellis;

    const checkSeanEllisModalEligibility = async () => {
      const eligibilityMap = await fetchAreModalsEligible(["sean_ellis"]);
      const isModalEligible = eligibilityMap.sean_ellis;
      toggleModal("seanEllis", isModalEligible);
    };

    if (isSeanEllisQuestionDebugEnabled) {
      toggleModal("seanEllis", true);
    } else if (!hasSeenSeanEllisQuestion && isSeanEllisQuestionEnabled) {
      checkSeanEllisModalEligibility();
    } else {
      toggleModal("seanEllis", false);
    }
  }, [
    corporateInfo,
    isSeanEllisQuestionEnabled,
    isSeanEllisQuestionDebugEnabled,
  ]);

  useEffect(() => {
    if (
      experimentsCallState === "loading" ||
      Object.values(modalConditions).some((condition) => condition === null)
    ) {
      return;
    }

    // note: determine the highest-priority modal to show
    const nextModal =
      MODAL_PRIORITY.find((modal) => modalConditions[modal]) || null;
    setTimeout(() => {
      setActiveModal(nextModal);
    }, 1000);
  }, [modalConditions, experimentsCallState]);

  const closeActiveModal = (modal: ModalType) => {
    setActiveModal(null);
    setModalConditions((prev) => ({
      ...prev,
      [modal]: false,
    }));
  };

  return { activeModal, closeActiveModal };
};
