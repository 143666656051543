import React from "react";
import {
  Box,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { CloseButton, CorporateTypography } from "halifax";
import classNames from "./styles.module.css";

interface Props {
  currentStep: number;
  title: string;
  subtitle: string;
  onClose: () => void;
  showDivider?: boolean;
  isOnboardingRevampEnabled?: boolean;
  isMobile?: boolean;
}

const Header = ({
  currentStep,
  title,
  subtitle,
  onClose,
  showDivider = false,
  isOnboardingRevampEnabled = false,
  isMobile = false,
}: Props): React.ReactElement => {
  const CustomConnector = withStyles({
    root: {
      flex: "0 0 30px !important",
    },
    line: {
      maxWidth: 30,
      minWidth: 30,
    },
  })(StepConnector);
  return (
    <Box
      className={classNames.container}
      style={isMobile ? { paddingLeft: 16, paddingRight: 16 } : undefined}
    >
      <div style={{ textAlign: "right", marginBottom: "24px" }}>
        <CloseButton onClick={onClose} />
      </div>
      <Box className={classNames.stepperRow}>
        <Stepper
          className={classNames.stepper}
          activeStep={currentStep}
          connector={
            !isOnboardingRevampEnabled ? undefined : <CustomConnector />
          }
        >
          <Step>
            <StepLabel>{isMobile ? `` : `TRAVELER `}PROFILE</StepLabel>
          </Step>
          <Step>
            <StepLabel>{isMobile ? `` : `ADDITIONAL `}DETAILS</StepLabel>
          </Step>
          {!isOnboardingRevampEnabled && (
            <Step>
              <StepLabel>TRAVEL POLICY</StepLabel>
            </Step>
          )}
        </Stepper>
      </Box>
      {isOnboardingRevampEnabled ? (
        <>
          <CorporateTypography
            variant={isMobile ? "h4" : "h1"}
            fontWeight="light"
          >
            {title}
          </CorporateTypography>
          <CorporateTypography
            variant={isMobile ? "body1" : "subtitle2"}
            fontWeight="light"
          >
            {subtitle}
          </CorporateTypography>
        </>
      ) : (
        <>
          <Typography
            variant="h2"
            component="h1"
            className={classNames.title}
            id="onboarding-title"
          >
            {title}
          </Typography>
          <Typography variant="body2" id="onboarding-subtitle">
            {subtitle}
          </Typography>
        </>
      )}
      {showDivider && <Divider />}
    </Box>
  );
};

export default Header;
