import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  USER_MEDIUM_KEY,
  USER_SOURCE_KEY,
  UTM_MEDIUM_PARAM,
  UTM_SOURCE_PARAM,
  UtmParams,
} from "@capone/common";

const UserSourceContext = createContext<UtmParams | null>(null);

export function useUtmParams(): UtmParams | null {
  const ctx = useContext(UserSourceContext);
  if (ctx === undefined)
    throw new Error(`must be used within a UserSourceProvider`);
  return ctx;
}

const UserSourceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userSource, setUserSource] = useState(
    sessionStorage.getItem(USER_SOURCE_KEY)
  );

  const [userMedium, setUserMedium] = useState(
    sessionStorage.getItem(USER_MEDIUM_KEY)
  );

  useEffect(() => {
    function checkUtmParams() {
      const param = new URLSearchParams(window.location?.search).get(
        UTM_SOURCE_PARAM
      );
      if (param) {
        setUserSource(param);
        sessionStorage.setItem(USER_SOURCE_KEY, param);
      }

      const mediumParam = new URLSearchParams(window.location?.search).get(
        UTM_MEDIUM_PARAM
      );
      if (mediumParam) {
        setUserMedium(mediumParam);
        sessionStorage.setItem(USER_MEDIUM_KEY, mediumParam);
      }
    }

    checkUtmParams();
    window.addEventListener("storage", checkUtmParams);
    return () => {
      window.removeEventListener("storage", checkUtmParams);
    };
  }, []);

  return (
    <UserSourceContext.Provider value={{ userSource, userMedium }}>
      {children}
    </UserSourceContext.Provider>
  );
};

export default UserSourceProvider;
