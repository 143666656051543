import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  MODAL_ALERT_CHOICE,
  ModalAlertProperties,
  ModalCategoryType,
  ModalButtonType,
  MODAL_ALERT,
} from "redmond";
import { useHistory } from "react-router-dom";
import { getUrlWithUtmParams } from "@capone/common";
import { GenericInfoPopup, useDeviceTypes } from "halifax";
import config from "../../../utils/config";
import { trackEvent } from "../../../api/v1/trackEvent";
import * as constants from "./constants";
import { HOME_PAGE_PATHS } from "../../../utils/urlPaths";
import { useUtmParams } from "../../../context/userSource";

const VIEWED_CORPORATE_SWITCH_MODAL_KEY = "viewedCorporateSwitchModal";

interface ICorporateSwitchModalProps {
  open: boolean;
}

const modalEventProperties: ModalAlertProperties = {
  type: "corporate_intent",
  primary_button: constants.BOOK_BUSINESS_BUTTON_TEXT,
  secondary_button: constants.CONTINUE_BUTTON_TEXT,
  screen: "confirming intent",
  modal_title: constants.SWITCH_MODAL_TITLE,
  modal_subtitle: constants.SWITCH_MODAL_SUBTITLE,
  category: ModalCategoryType.FEATURE,
  agent_title: constants.SWITCH_MODAL_TITLE,
  agent_subtitle: constants.SWITCH_MODAL_SUBTITLE,
  step: "",
  funnel: "other",
};

export const CorporateSwitchModal = ({ open }: ICorporateSwitchModalProps) => {
  const { matchesMobile } = useDeviceTypes();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fromCorporate] = useState<boolean>(
    localStorage.getItem("fromCorporate") === "true"
  );
  const [isPersistentDismiss, setIsPersistentDismiss] = useState(false);

  const history = useHistory();
  const utmParams = useUtmParams();

  const cobtRedirectUrl = getUrlWithUtmParams(
    config.corporatePortalUrl || "",
    utmParams
  );

  const isHomePage = HOME_PAGE_PATHS.includes(history.location.pathname);

  useEffect(() => {
    localStorage.removeItem("fromCorporate");
  }, []);

  const hasViewedModal = localStorage.getItem(
    VIEWED_CORPORATE_SWITCH_MODAL_KEY
  );

  const setViewedModal = () => {
    if (isPersistentDismiss) {
      localStorage.setItem(
        VIEWED_CORPORATE_SWITCH_MODAL_KEY,
        Date.now().toString()
      );
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (!hasViewedModal && open) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: modalEventProperties,
      });

      setIsOpen(true);
    }
  }, [hasViewedModal, open]);

  const buttons = [
    {
      buttonText: constants.CONTINUE_BUTTON_TEXT,
      buttonWrapperClassName: "corporate-switch-modal-button",
      defaultStyle: "h4r-secondary" as const,
      onClick: () => {
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.SECONDARY,
          },
        });
        setViewedModal();
      },
    },
    {
      buttonText: constants.BOOK_BUSINESS_BUTTON_TEXT,
      buttonWrapperClassName: "corporate-switch-modal-button",
      defaultStyle: "h4r-primary" as const,
      onClick: () => {
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.PRIMARY,
          },
        });
        trackEvent({
          eventName: "portal_switched",
          properties: {
            current_portal: "consumer",
            portal_selected: "business",
          },
        });
        window.location.assign(cobtRedirectUrl);
        setViewedModal();
      },
    },
  ];

  if (fromCorporate || hasViewedModal || !isHomePage) {
    return null;
  }

  const persistentDismissLabelId = "corporate-persistent-dismiss-label";

  return (
    <GenericInfoPopup
      buttons={buttons}
      image={
        <Typography
          variant="h4"
          style={{ color: "var(--secondary-text-grey)" }}
        >
          Welcome to Capital One Travel
        </Typography>
      }
      isAgent={false}
      isMobile={matchesMobile}
      open={isOpen}
      subtitle={constants.SWITCH_MODAL_SUBTITLE}
      title={constants.SWITCH_MODAL_TITLE}
      bottomContent={
        <Box mt={1}>
          <FormControlLabel
            labelPlacement="end"
            control={
              <Checkbox
                inputProps={{ "aria-labelledby": persistentDismissLabelId }}
                checked={isPersistentDismiss}
                onChange={(e) => setIsPersistentDismiss(e.target.checked)}
              />
            }
            label={
              <Typography id={persistentDismissLabelId}>
                Do not ask me again
              </Typography>
            }
          />
        </Box>
      }
    />
  );
};

export default CorporateSwitchModal;
