import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  CorpModalScreens,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalAlertProperties,
  ModalButtonType,
  ModalCategoryType,
} from "redmond";
import {
  CloseButton,
  CorporateButton,
  CorporateTypography,
  Icon,
  IconName,
} from "halifax";
import React, { useEffect, useMemo } from "react";
import { WelcomeV2Props } from "../types";
import capOneLogo from "../Welcome/capone-logo-white.svg";
import "./styles.scss";
import { trackEvent } from "../../../../../api/v1/trackEvent";

const valuePropItemsPrimary = [
  {
    icon: <Icon name={IconName.CorpRewards} />,
    label: (
      <>
        Earn the same
        <strong> unlimited 5% cash back </strong>
        on hotels & cars.
      </>
    ),
  },
  {
    icon: <Icon name={IconName.CorpPercentage} />,
    label: (
      <>
        Enjoy new benefits like up
        <strong> 10% off hotels with corporate rates </strong>
        and
        <strong> Southwest booking access.</strong>
      </>
    ),
  },
  {
    icon: <Icon name={IconName.CorpSettings} />,
    label: (
      <>
        Manage travelers, bookings and expenses
        <strong> on one platform.</strong>
      </>
    ),
  },
];
const valuePropItemsNonPrimary = [
  {
    icon: <Icon name={IconName.CorpSavings} />,
    label: (
      <>
        <strong>Save up to 10% </strong>
        on hotels with our discounted corporate rates.
      </>
    ),
  },
  {
    icon: <Icon name={IconName.CorpRewards} />,
    label: (
      <>
        Earn
        <strong> loyalty points </strong>
        and
        <strong> with your favorite hotel and airline brands.</strong>
      </>
    ),
  },
  {
    icon: <Icon name={IconName.CorpPlane} />,
    label: (
      <>
        Enjoy exclusive business access to
        <strong> Southwest flights.</strong>
      </>
    ),
  },
];

export const WelcomeV2: React.FC<WelcomeV2Props> = ({
  onClose,
  primaryAction,
  secondaryAction,
  isLoading,
  username,
  isPrimary,
  isSecondaryActionLoading,
  isMobile = false,
}) => {
  const valueProps = isPrimary
    ? valuePropItemsPrimary
    : valuePropItemsNonPrimary;

  const modalTitle = username ? `Welcome, ${username}!` : "Welcome";
  const modalSubtitle =
    "Your Spark Cash Select account has been upgraded to our business-travel platform.";

  const commonTrackingProperties = useMemo(
    () =>
      ({
        type: isPrimary ? "welcome_modal_primary" : "welcome_modal_nonprimary",
        screen: CorpModalScreens.ONBOARDING_WELCOME,
        primary_button: "Book travel",
        secondary_button: isPrimary ? "Set up my business" : "",
        step: CorpModalScreens.ONBOARDING_WELCOME,
        funnel: "other",
        category: ModalCategoryType.FEATURE,
        modal_subtitle: modalSubtitle,
        modal_title: modalTitle,
        agent_title: modalTitle,
        agent_subtitle: modalSubtitle,
      } satisfies ModalAlertProperties),
    []
  );

  useEffect(() => {
    trackEvent({
      eventName: MODAL_ALERT,
      properties: commonTrackingProperties,
    });
  }, []);

  const bookTravelClicked = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.PRIMARY,
      },
    });
    primaryAction();
  };

  const setupMyAccountClicked = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        ...commonTrackingProperties,
        button_choice: ModalButtonType.SECONDARY,
      },
    });
    secondaryAction();
  };

  const bookTravelAction = (
    <CorporateButton
      size="large"
      onClick={bookTravelClicked}
      data-testid="welcome-modal-primary-cta"
      fullWidth={isMobile}
    >
      {isLoading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        `Book travel`
      )}
    </CorporateButton>
  );

  const setupBusinessAction = isPrimary ? (
    <CorporateButton
      size="large"
      variant="outlined"
      onClick={setupMyAccountClicked}
      fullWidth={isMobile}
    >
      {isSecondaryActionLoading ? (
        <CircularProgress size={16} color="inherit" />
      ) : (
        `Setup my business`
      )}
    </CorporateButton>
  ) : null;

  const mobileWelcomeModal = (
    <Box
      className="welcome-dialog welcome-dialog-mobile"
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <DialogTitle className="dialog-title MuiDialogTitle-root mobile-dialog-title">
        <CloseButton onClick={onClose} />
        <Box className="mobile-image-container">
          <Icon name={IconName.CorpBookingTravelMobile} />
        </Box>
        <CorporateTypography variant="h2" className="mobile-title">
          {username ? `Welcome, ${username}!` : "Welcome"}
        </CorporateTypography>
        <CorporateTypography
          variant="subtitle2"
          fontWeight="light"
          className="mobile-subtitle"
        >
          Your Spark Cash Select account has been upgraded to our
          business-travel platform.
        </CorporateTypography>
      </DialogTitle>

      <DialogContent style={{ flex: "1 1 auto", overflow: "auto" }}>
        <Box className="mobile-divider" />

        <Box className="mobile-value-props-container">
          {valueProps.map((item, index) => (
            <Box key={index} display="flex" className="mobile-value-prop-item">
              <Box className="mobile-icon-box">{item.icon}</Box>
              <CorporateTypography
                variant="body1"
                fontWeight="light"
                className="mobile-value-prop-text"
              >
                {item.label}
              </CorporateTypography>
            </Box>
          ))}
        </Box>

        <CorporateTypography variant="body2" className="mobile-footer-text">
          Any booked trips or traveler profiles are still here—along with
          powerful new features.{" "}
          <a
            href="https://businesstravel.capitalone.com/faq/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "underline", color: "inherit" }}
          >
            Learn more
          </a>{" "}
          about business travel
        </CorporateTypography>
        <Box className="mobile-divider" />
      </DialogContent>

      <DialogActions className="dialog-actions MuiDialogActions-root mobile-dialog-actions">
        {bookTravelAction}
        {setupBusinessAction}
      </DialogActions>
    </Box>
  );
  const desktopWelcomeModal = (
    <Box className="welcome-dialog">
      <DialogTitle className="dialog-title MuiDialogTitle-root">
        <CloseButton onClick={onClose} />
        <CorporateTypography variant="h1" fontWeight="light">
          {modalTitle}
        </CorporateTypography>
        <CorporateTypography variant="h5" fontWeight="light">
          {modalSubtitle}
        </CorporateTypography>
      </DialogTitle>

      <DialogContent className="dialog-content">
        <Box className="value-props-box">
          <Box className="logo">
            <img
              src={capOneLogo}
              alt="Capital One Business Travel"
              style={{
                height: 52,
                width: "auto",
              }}
            />
          </Box>

          {valueProps.map((item, index) => (
            <Box key={index} display="flex" className="value-prop-item">
              <Box className="icon-box">{item.icon}</Box>
              <CorporateTypography
                variant="body1"
                className="value-prop-item-text"
              >
                {item.label}
              </CorporateTypography>
            </Box>
          ))}

          <CorporateTypography variant="body2" className="value-prop-item-text">
            Any booked trips or traveler profiles are still here—along with
            powerful new features.{" "}
            <a
              href="https://businesstravel.capitalone.com/faq/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline", color: "inherit" }}
            >
              Learn more
            </a>{" "}
            about business travel
          </CorporateTypography>
        </Box>
      </DialogContent>

      <DialogActions className="dialog-actions MuiDialogActions-root">
        {setupBusinessAction}
        {bookTravelAction}
      </DialogActions>
    </Box>
  );
  return isMobile ? mobileWelcomeModal : desktopWelcomeModal;
};

export default WelcomeV2;
