import { isCorpTenant } from "@capone/common";
import { Box, Collapse } from "@material-ui/core";
import clsx from "clsx";
import {
  BackButton,
  CorporateTypography,
  PremierCollectionBenefitsModal,
  useDeviceTypes,
} from "halifax";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
// import { TransitionGroup } from "react-transition-group";
import {
  IIdLodgings,
  IResponse,
  LocationQueryEnum,
  LodgingSelection,
  LodgingSelectionEnum,
  PRODUCT_FEED_VIEWED_EVENT,
  ProductFeedViewedProperties,
  ProductTileType,
  RecentCarSearch,
  RecentFlightSearch,
  RecentHotelSearch,
  RecentlyViewedHotel,
  RecentPackageSearch,
} from "redmond";
import { config } from "../../api/config";
import { fetchLocationAutocomplete } from "../../api/v1/recently-viewed-and-searched/fetchLocationAutocomplete";
import { trackEvent } from "../../api/v1/trackEvent";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  PREMIER_COLLECTION_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_V1,
  RECENTLY_VIEWED_DESKTOP_P0_V2,
  RECENTLY_VIEWED_DESKTOP_P0_VARIANTS,
  RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
  RECENTLY_VIEWED_MOBILE_P0_V1,
  RECENTLY_VIEWED_MOBILE_P0_V2,
  RECENTLY_VIEWED_MOBILE_P0_V3,
  RECENTLY_VIEWED_MOBILE_P0_VARIANTS,
  useExperiments,
} from "../../context/experiments";
import { RecentlyViewedAndSearchedProps } from "../Body/recently-viewed-and-searched-helpers";
import { RecentlySearchedCard } from "./components/RecentlySearchedCard";
import { RecentlySearchedSkeletonCard } from "./components/RecentlySearchedSkeletonCard/component";
import { RecentlyViewedCard } from "./components/RecentlyViewedCard";
import "./styles.scss";

export const RecentlyViewedAndSearched = (
  props: RecentlyViewedAndSearchedProps
) => {
  const {
    recentSearchesAndViews = [],
    largestValueAccount,
    latency = 0,
    negativeMargin = true,
    showSkeleton = false,
    loading,
  } = props;
  const [isOverFlow, setIsOverFlow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [lodgingSelectionsMap, setLodgingSelectionsMap] = useState<{
    [key: string]: LodgingSelection;
  }>({});
  const [hasRecentlyViewedHotel, setHasRecentlyViewedHotel] = useState(false);
  const [hasRecentlySearchedHotel, setHasRecentlySearchedHotel] =
    useState(false);
  const [hasRecentlySearchedFlight, setHasRecentlySearchedFlight] =
    useState(false);
  const [hasRecentlySearchedCar, setHasRecentlySearchedCar] = useState(false);
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
    RECENTLY_VIEWED_DESKTOP_P0_VARIANTS
  );

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const isCorpOnboardingRevampEnabled =
    isCorpTenant(config.TENANT) &&
    (getExperimentVariant(expState.experiments, "corp-onboarding-revamp") ===
      "available" ||
      getExperimentVariant(expState.experiments, "corp-onboarding-revamp") ===
        "debug");

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
    RECENTLY_VIEWED_MOBILE_P0_VARIANTS
  );

  const showSkeletonForLoadingState = showSkeleton && loading;

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const { ref } = useInView({
    root: document.getElementById("recently-searched-card-view-wrapper"),
    threshold: 0.5,
    rootMargin: "0px 0px 0px 350px",
  });

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };
  const determineOverFlow = () => {
    if (!matchesMobile) {
      const overFlowedElement = document.getElementById(
        "recently-viewed-and-searched-cards-wrapper"
      );

      if (overFlowedElement) {
        return overFlowedElement.clientWidth < overFlowedElement.scrollWidth;
      }
    }
    return false;
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    const overFlow = determineOverFlow();
    setIsOverFlow(overFlow || (!matchesMobile && showSkeletonForLoadingState));
  }, [windowWidth, showSkeleton, loading, matchesMobile]);

  useEffect(() => {
    if (recentSearchesAndViews?.length) {
      if (!matchesMobile) {
        (async () => {
          const autocompleteMap: {
            [key: string]: LodgingSelection;
          } = {};

          for (let i = 0; i <= recentSearchesAndViews.length; i++) {
            const recentItem = recentSearchesAndViews[i] as RecentHotelSearch;
            if (
              recentItem &&
              !(recentItem?.location in autocompleteMap) &&
              ("lodging" in recentItem ||
                recentlyViewedDesktopP0Variant ===
                  RECENTLY_VIEWED_DESKTOP_P0_V1 ||
                recentlyViewedDesktopP0Variant ===
                  RECENTLY_VIEWED_DESKTOP_P0_V2)
            ) {
              const fetchLocation = async () => {
                try {
                  const { categories: locationCategories }: IResponse =
                    await fetchLocationAutocomplete({
                      l: recentItem.location,
                      LocationQuery: LocationQueryEnum.Label,
                    });
                  const correspondingLocations = locationCategories.flatMap(
                    (category) =>
                      category.results.find((result) =>
                        result.label
                          .toLowerCase()
                          .includes(recentItem.location.toLowerCase())
                      )
                  );
                  const allLocations = locationCategories.flatMap(
                    (category) => category.results
                  );
                  const updatedLocation =
                    correspondingLocations.length > 0
                      ? correspondingLocations[0]
                      : allLocations[0];
                  autocompleteMap[recentItem.location] = (
                    updatedLocation?.id as IIdLodgings
                  ).lodgingSelection;
                } catch {
                  autocompleteMap[recentItem.location] = {
                    placeId: "",
                    searchTerm: recentItem.location,
                    placeTypes: ["locality", "political", "geocode"],
                    LodgingSelection: LodgingSelectionEnum.Place,
                  };
                }
              };
              await fetchLocation();
            }
            setLodgingSelectionsMap((prevState) => ({
              ...prevState,
              ...autocompleteMap,
            }));
          }
        })();
      }

      const recentlyViewedSellProps: ProductFeedViewedProperties = {
        feed_placement: "homepage",
        feed_type: "recently_viewed",
        latency,
        tile_type: [
          ...(hasRecentlyViewedHotel
            ? (["recently_viewed_hotel"] as ProductTileType[])
            : []),
          ...(hasRecentlySearchedHotel
            ? (["recently_searched_hotel"] as ProductTileType[])
            : []),
          ...(hasRecentlySearchedFlight
            ? (["recently_searched_air"] as ProductTileType[])
            : []),
          ...(hasRecentlySearchedCar
            ? (["recently_searched_car"] as ProductTileType[])
            : []),
        ],
      };
      trackEvent({
        eventName: PRODUCT_FEED_VIEWED_EVENT,
        properties: {
          ...recentlyViewedSellProps,
        },
      });
    }
  }, [
    recentSearchesAndViews,
    matchesMobile,
    latency,
    recentlyViewedDesktopP0Variant,
    hasRecentlyViewedHotel,
    hasRecentlySearchedHotel,
    hasRecentlySearchedFlight,
    hasRecentlySearchedCar,
  ]);

  const leftScrollButton = () => {
    const overFlowedElement = document.getElementById(
      "recently-viewed-and-searched-cards-wrapper"
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft -= 350;
    }
  };
  const rightScrollButton = () => {
    const overFlowedElement = document.getElementById(
      "recently-viewed-and-searched-cards-wrapper"
    );
    if (overFlowedElement) {
      overFlowedElement.scrollLeft += 350;
    }
  };

  const handleOpenPCBenefitsModal = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePCBenefitsModal = () => {
    setAnchorEl(null);
  };

  const noData =
    (!!recentSearchesAndViews && !recentSearchesAndViews.length) ||
    !largestValueAccount ||
    !latency;
  // when skeleton is shown hide component when loading is over and no data or
  // when skeletong is not shown hide if loading or no data
  const hideComponent =
    (showSkeleton && !loading && noData) || (!showSkeleton && (loading || noData));

  const renderSkeletonCards = () => [
    <RecentlySearchedSkeletonCard key="recently-viewed-0" />,
    <RecentlySearchedSkeletonCard key="recently-viewed-1" />,
    <RecentlySearchedSkeletonCard key="recently-viewed-2" />,
    <RecentlySearchedSkeletonCard key="recently-viewed-3" />,
    <RecentlySearchedSkeletonCard key="recently-viewed-4" />,
  ];

  const renderRecentlySearchedAndViewsCards = () => {
    if (!largestValueAccount) {
      return null;
    }
    let recentlySearchedHotelCardCount = 0; // this is used to alternate background image
    let recentlySearchedFlightCardCount = 0;
    let recentlySearchedCarCardCount = 0;
    return recentSearchesAndViews.map(
      (
        recentItem:
          | RecentHotelSearch
          | RecentlyViewedHotel
          | RecentFlightSearch
          | RecentCarSearch
          | RecentPackageSearch,
        index: number
      ) => {
        if ("lodging" in recentItem) {
          if (!hasRecentlyViewedHotel) {
            setHasRecentlyViewedHotel(true);
          }
          return (
            <div
              ref={ref}
              className="recently-searched-card-view-wrapper recently-viewed"
              // eslint-disable-next-line react/no-array-index-key
              key={`recently-viewed-${index}`}
            >
              <RecentlyViewedCard
                recentView={recentItem as RecentlyViewedHotel}
                largestValueAccount={largestValueAccount}
                index={index}
                lodgingSelection={lodgingSelectionsMap[recentItem.location]}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                onPCBannerClick={handleOpenPCBenefitsModal}
                isCacheHotelTokenEnabled={cacheHotelTokenEnabled}
              />
            </div>
          );
        }
        if ((recentItem as RecentHotelSearch).location) {
          recentlySearchedHotelCardCount += 1;
          if (!hasRecentlySearchedHotel) {
            setHasRecentlySearchedHotel(true);
          }
          return (
            <div
              ref={ref}
              className={clsx(
                "recently-searched-card-view-wrapper recently-searched",
                {
                  "bg-1": recentlySearchedHotelCardCount % 3 === 1,
                  "bg-2": recentlySearchedHotelCardCount % 3 === 2,
                  "bg-3": recentlySearchedHotelCardCount % 3 === 0,
                }
              )}
              // eslint-disable-next-line react/no-array-index-key
              key={`recently-searched-${index}`}
            >
              <RecentlySearchedCard
                recentSearch={recentItem as RecentHotelSearch}
                largestValueAccount={largestValueAccount}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                lodgingSelection={
                  lodgingSelectionsMap[
                    (recentItem as RecentHotelSearch).location
                  ]
                }
                uiVariant="hotels"
              />
            </div>
          );
        }
        if ((recentItem as RecentFlightSearch).departureDate) {
          recentlySearchedFlightCardCount += 1;
          if (!hasRecentlySearchedFlight) {
            setHasRecentlySearchedFlight(true);
          }
          return (
            <div
              ref={ref}
              className={clsx(
                "recently-searched-card-view-wrapper recently-searched",
                {
                  "bg-1": recentlySearchedFlightCardCount % 2 === 0,
                  "bg-2": recentlySearchedFlightCardCount % 2 === 1,
                }
              )}
              // eslint-disable-next-line react/no-array-index-key
              key={`recently-searched-${index}`}
            >
              <RecentlySearchedCard
                recentSearch={recentItem as RecentFlightSearch}
                largestValueAccount={largestValueAccount}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                uiVariant="flights"
              />
            </div>
          );
        }
        if ((recentItem as RecentCarSearch).pickUpTime) {
          recentlySearchedCarCardCount += 1;
          if (!hasRecentlySearchedCar) {
            setHasRecentlySearchedCar(true);
          }
          return (
            <div
              ref={ref}
              className={clsx(
                "recently-searched-card-view-wrapper recently-searched",
                {
                  "bg-1": recentlySearchedCarCardCount % 2 === 0,
                  "bg-2": recentlySearchedCarCardCount % 2 === 1,
                }
              )}
              // eslint-disable-next-line react/no-array-index-key
              key={`recently-searched-${index}`}
            >
              <RecentlySearchedCard
                recentSearch={recentItem as RecentCarSearch}
                largestValueAccount={largestValueAccount}
                variant={
                  matchesMobile
                    ? recentlyViewedMobileP0Variant
                    : recentlyViewedDesktopP0Variant
                }
                uiVariant="cars"
              />
            </div>
          );
        }
        return null;
      }
    );
  };

  return (
    <Collapse in={!hideComponent}>
      <Box
        className={clsx("recently-viewed-and-searched-root", {
          mobile: matchesMobile,
          "mobile-v1":
            recentlyViewedMobileP0Variant === RECENTLY_VIEWED_MOBILE_P0_V1,
          "mobile-v2":
            recentlyViewedMobileP0Variant === RECENTLY_VIEWED_MOBILE_P0_V2,
          "mobile-v3":
            recentlyViewedMobileP0Variant === RECENTLY_VIEWED_MOBILE_P0_V3,
          "negative-margin": negativeMargin,
          "corp-onboarding-revamp": isCorpOnboardingRevampEnabled,
        })}
      >
        {isPremierCollectionEnabled && (
          <PremierCollectionBenefitsModal
            handleClosePCBenefitsModal={handleClosePCBenefitsModal}
            anchorEl={anchorEl}
            openBenefitsModal={!!anchorEl}
            largestValueAccount={largestValueAccount}
          />
        )}

        <Box
          className={clsx("title", {
            overflow: isOverFlow,
          })}
        >
          {isCorpOnboardingRevampEnabled ? (
            <CorporateTypography
              variant={matchesMobile ? "h4" : "h2"}
              fontWeight={matchesMobile ? "regular" : "light"}
            >
              Continue your search
            </CorporateTypography>
          ) : (
            "Continue your search"
          )}
        </Box>
        <Box
          className={clsx(
            "recently-viewed-and-searched-cards-carousel-wrapper",
            {
              overflow: isOverFlow,
            }
          )}
        >
          {isOverFlow && (
            <BackButton
              onClick={leftScrollButton}
              className="left-scroll-button"
            />
          )}
          <Box
            id="recently-viewed-and-searched-cards-wrapper"
            className="recently-viewed-and-searched-cards-wrapper"
          >
            {showSkeletonForLoadingState
              ? renderSkeletonCards()
              : renderRecentlySearchedAndViewsCards()}
          </Box>
          {isOverFlow && (
            <BackButton
              onClick={rightScrollButton}
              className="right-scroll-button"
            />
          )}
        </Box>
      </Box>
    </Collapse>
  );
};
