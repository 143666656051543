import {
  ButtonWrap,
  Header as GenericHeader,
  HamburgerButton,
  Icon,
  IconName,
} from "halifax";
import React, { useContext, useEffect, useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import {
  CorpUserContext,
  getUrlWithUtmParams,
  HAS_CHOSEN_ACCOUNT,
} from "@capone/common";
import { useExperimentsById } from "@capone/experiments";
import { Badge, Box } from "@material-ui/core";
import clsx from "clsx";
import {
  CorpBusinessAccount,
  CorpSessionInfo,
  CustomEvents,
  RewardsAccount,
  WalletSummaryResponse,
} from "redmond";
import {
  PATH_CARS,
  PATH_CARS_BOOK_CONFIRMATION,
  PATH_CARS_SEARCH,
  PATH_FLIGHTS,
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  PATH_FLIGHTS_SEARCH,
  PATH_HOME,
  PATH_HOTELS,
  PATH_HOTELS_BOOK_CONFIRMATION,
  PATH_HOTELS_SEARCH,
  PATH_PREMIER_COLLECTION_SEARCH,
  PATH_PREMIER_VACATION_RENTALS_SEARCH,
  PATH_TRAVEL_WALLET,
} from "../../../../utils/urlPaths";
import CorporateLogo from "../../../../assets/b2b/capone-corporate-logo.svg";
import DBCCorporateLogo from "../../../../assets/b2b/capone-corporate-logo-dbc.svg";
import { fetchTravelWalletOffers } from "../../../../api/v1/takeovers/fetchTravelWalletOffers";
import {
  AVAILABLE,
  EXPERIENCES_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import config from "../../../../utils/capone-corporate/config";
import { CAP_ONE_CORP_LOGO_ALT } from "../../../../utils/constants";
import { HIDE_ON_SCROLL_PATHS } from "../../../Header/components/MobileHeader";
import MobileHamburgerMenu from "../../../MobileHamburgerMenu";
import { MaintenanceBanner } from "../../Maintenance/MaintenanceBanner";
import { CorpMultiAccountSwitcherModal } from "../../MultiAccount/CorpMultiAccountSwitcherModal/component";
import { useSwitchBusinessAccount } from "../../MultiAccount/useSwitchBusinessAccount";
import { MULTI_ACCOUNT_MODAL_TITLE } from "./constants";
import { useUtmParams } from "../../../../context/userSource";
import "./styles.scss";
import { OnboardingModal } from "../../OnboardingModal";

interface IMobileHeaderProps {
  language: string;
  canViewConsumer: boolean;
  corpBusinessAccounts?: CorpBusinessAccount[];
  className?: string;
  rewardsAccounts?: RewardsAccount[];
  selectedRewardsAccountId: string | null;
}

const MobileHeader = (_props: IMobileHeaderProps) => {
  const { className, rewardsAccounts, selectedRewardsAccountId } = _props;
  const [showMenu, setShowMenu] = React.useState(false);
  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [offerCount, setOfferCount] = React.useState(0);

  const { canViewConsumer, corpBusinessAccounts } = _props;
  const { sessionInfo, policies } = useContext(CorpUserContext);
  const { handleChangeBusinessInUse, isAccountSwitchLoading } =
    useSwitchBusinessAccount();
  const currentAccount = rewardsAccounts?.find(
    (account) => account.accountReferenceId === selectedRewardsAccountId
  );
  const utmParams = useUtmParams();

  const consumerPortalUrl = getUrlWithUtmParams(
    config.consumerPortalUrl || "",
    utmParams
  );

  const adminPortalUrl = getUrlWithUtmParams(
    config.adminPortalUrlWithSource,
    utmParams
  );

  const maintenanceExperiment = useExperimentsById("corp-traveler-maintenance");
  const isMaintenaceBannerActive =
    Boolean(maintenanceExperiment) &&
    maintenanceExperiment?.variant === "banner";

  const expState = useExperiments();
  const isTravelWalletOfferExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_OFFER_EXPERIMENT
    ) === AVAILABLE;

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const isExperiencesExperiment =
    getExperimentVariant(expState.experiments, EXPERIENCES_EXPERIMENT) ===
    AVAILABLE;

  const [showCorpAccountPickModal, setShowCorpAccountPickModal] =
    useState(false);

  const handleCloseCorpAccountPickModal = () => {
    sessionStorage.setItem(HAS_CHOSEN_ACCOUNT, "true");

    setShowCorpAccountPickModal(false);
  };

  const hasCorpBusinessAccounts =
    corpBusinessAccounts && corpBusinessAccounts?.length > 1;

  useEffect(() => {
    if (
      sessionStorage.getItem(HAS_CHOSEN_ACCOUNT) !== "true" &&
      hasCorpBusinessAccounts
    ) {
      setShowCorpAccountPickModal(true);
    }
  }, [hasCorpBusinessAccounts]);

  useEffect(() => {
    fetchTravelWalletOffers().then((response) => {
      const hasCredit = !!(response as WalletSummaryResponse).credit;
      const newOfferCount = (response as WalletSummaryResponse).offerCount || 0;
      const totalCount = hasCredit ? newOfferCount + 1 : newOfferCount;
      setOfferCount(totalCount);
    });
  }, []);

  const canViewAdmin = sessionInfo?.corporateInfo.permissions?.canViewAdmin;

  const showSupportModal = () => {
    setShowMenu(true);
    setOpenSupportModal(true);
  };

  useEffect(() => {
    document.addEventListener(CustomEvents.showSupportModal, showSupportModal);
    return () => {
      document.removeEventListener(
        CustomEvents.showSupportModal,
        showSupportModal
      );
    };
  }, []);

  const hamburger =
    isTravelWalletOfferExperiment && !isGlobalMobileNavExperiment ? (
      <Badge badgeContent={offerCount} className="offer-count">
        <HamburgerButton
          onOpen={() => setShowMenu(true)}
          onClose={() => setShowMenu(false)}
          open={showMenu}
          menu={
            <MobileHamburgerMenu
              onClose={() => setShowMenu(false)}
              offerCount={offerCount}
              openSupportModal={openSupportModal}
              setOpenSupportModal={setOpenSupportModal}
              isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
              isPremierCollectionEnabled={isPremierCollectionEnabled}
              tenant="capone-corporate"
              consumerPortalUrl={canViewConsumer ? consumerPortalUrl : ""}
              adminPortalUrl={canViewAdmin ? adminPortalUrl : ""}
              isExperiencesExperiment={isExperiencesExperiment}
            />
          }
        />
      </Badge>
    ) : (
      <HamburgerButton
        onOpen={() => setShowMenu(true)}
        onClose={() => setShowMenu(false)}
        open={showMenu}
        menu={
          <MobileHamburgerMenu
            onClose={() => setShowMenu(false)}
            openSupportModal={openSupportModal}
            setOpenSupportModal={setOpenSupportModal}
            offerCount={offerCount}
            isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
            tenant="capone-corporate"
            consumerPortalUrl={canViewConsumer ? consumerPortalUrl : ""}
            adminPortalUrl={canViewAdmin ? adminPortalUrl : ""}
            isExperiencesExperiment={isExperiencesExperiment}
          />
        }
      />
    );

  const showDBCLogo =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const renderGenericHeader = (
    isConfirmation: boolean,
    browserRouterProps: RouteComponentProps,
    hideOnScroll: boolean
  ) => (
    <>
      <GenericHeader
        isMobile
        shadow={className === "homepage"}
        className={clsx("b2b", "corp", className, {
          confirmation: isConfirmation,
          "redesign-v2":
            mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
          "has-logo": isGlobalMobileNavExperiment,
        })}
        middle={
          isMaintenaceBannerActive && (
            // Negative margin to undo the padding on mobile b2b-header
            <div style={{ margin: "0 -1rem" }}>
              <MaintenanceBanner
                title={maintenanceExperiment.data.title}
                subtext={maintenanceExperiment.data.subtext}
                severity={maintenanceExperiment.data.severity}
              />
            </div>
          )
        }
        left={
          <ButtonWrap
            onClick={() => browserRouterProps.history.push(PATH_HOME)}
          >
            <Box className="logo-container">
              <img
                className="mobile-logo"
                src={showDBCLogo ? DBCCorporateLogo : CorporateLogo}
                alt={CAP_ONE_CORP_LOGO_ALT}
              />
            </Box>
          </ButtonWrap>
        }
        right={hamburger}
        hideOnScroll={hideOnScroll}
      />
      {policies && sessionInfo && (
        <OnboardingModal policies={policies} currentAccount={currentAccount} />
      )}
      {hasCorpBusinessAccounts && (
        <CorpMultiAccountSwitcherModal
          open={showCorpAccountPickModal}
          onClose={handleCloseCorpAccountPickModal}
          headerImage={
            <Icon
              className="corp-account-picker-icon-mobile"
              name={IconName.MultiAccountCards}
            />
          }
          title={MULTI_ACCOUNT_MODAL_TITLE}
          accounts={corpBusinessAccounts}
          sessionInfo={sessionInfo as CorpSessionInfo}
          handleChangeBusinessInUse={handleChangeBusinessInUse}
          isAccountSwitchLoading={isAccountSwitchLoading}
        />
      )}
    </>
  );

  return (
    <Switch>
      <Route
        path={[PATH_FLIGHTS, PATH_HOTELS, PATH_CARS, PATH_TRAVEL_WALLET]}
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(false, browserRouterProps, false)
        }
        exact
      />
      <Route
        path={[
          PATH_FLIGHTS_BOOK_CONFIRMATION,
          PATH_HOTELS_BOOK_CONFIRMATION,
          PATH_CARS_BOOK_CONFIRMATION,
        ]}
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(true, browserRouterProps, false)
        }
        exact
      />
      <Route
        path={PATH_FLIGHTS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />
      <Route
        path={PATH_HOTELS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />
      <Route
        path={PATH_CARS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />
      <Route
        path={[
          PATH_PREMIER_COLLECTION_SEARCH,
          PATH_PREMIER_VACATION_RENTALS_SEARCH,
        ]}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />

      <Route
        path="*"
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(
            false,
            browserRouterProps,
            isGlobalMobileNavExperiment &&
              HIDE_ON_SCROLL_PATHS.includes(
                browserRouterProps.location.pathname
              )
          )
        }
      />
    </Switch>
  );
};

export default MobileHeader;
