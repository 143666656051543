import {
  useExperimentIsVariant,
  useExperimentsById,
} from "@capone/experiments";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionButton,
  ActionLink,
  CorpSurveyModal,
  DesktopPopupModal,
  Header as GenericHeader,
  getTravelCreditAndOfferTitle,
  HeaderHomePageRightSection,
  HeaderMenuLinkType,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  matchPath,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import {
  B2B_PORTAL_ENDSESSION_PATH,
  CorpBusinessAccount,
  CorpSessionInfo,
  CustomEvents,
  DropdownAction,
  EditTravelerModalState,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
  ModalNames,
  PORTAL_SWITCHED,
  PortalSwitchedProperties,
  PortalTypes,
  RewardsAccount,
  SELECT_EDIT_TRAVELERS,
  SelectEditTravelersProperties,
  VIEWED_CONTACT_MODAL,
  WalletSummaryResponse,
} from "redmond";

import {
  getUrlWithUtmParams,
  HAS_CHOSEN_ACCOUNT,
  isCorpTenant,
  PATH_CORP_FAQ,
  useUserContext,
} from "@capone/common";
import { fetchTravelWalletOffers } from "../../../../api/v1/takeovers/fetchTravelWalletOffers";
import { trackEvent } from "../../../../api/v1/trackEvent";
import { updateUserSeenModal } from "../../../../api/v1/user/updateUserSeenModal";
import DBCCorporateLogo from "../../../../assets/b2b/capone-corporate-logo-dbc.svg";
import CorporateLogo from "../../../../assets/b2b/capone-corporate-logo.svg";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_CREDIT_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import config from "../../../../utils/capone-corporate/config";
import { CAP_ONE_CORP_LOGO_ALT } from "../../../../utils/constants";
import {
  HIDDEN_FUNNEL_ENTRY_HEADER_PATHS,
  HIDDEN_HEADER_DETAILS_PATHS,
  PATH_AUTH_INVALID_SESSION,
  PATH_CARS,
  PATH_CORP_ACCESS_DENIED,
  PATH_CORP_ACCESS_DENIED_NOT_BETA,
  PATH_CORP_ACKNOWLEDGEMENT,
  PATH_FLIGHTS,
  PATH_HOME,
  PATH_HOTELS,
  PATH_PREMIER_COLLECTION,
  PATH_SITE_TERMS_OF_USE,
  PATH_TERMS,
  PATH_TERMS_OF_SERVICE,
  PATH_TRAVEL_SALE,
  PATH_TRAVEL_WALLET,
  PATH_TRIPS,
  UNPROTECTED_PATHS,
} from "../../../../utils/urlPaths";
import * as textConstants from "../../../constants";
import { FunnelEntryTabs } from "../../../Header/components/FunnelEntryTabs";
import { SupportModalContent } from "../../../Header/components/SupportModal";
import { OnboardingModal } from "../../OnboardingModal";
import { RewardsAccountSelection } from "../../../RewardsAccountSelection";
import FintechSubscriptionV3Flow from "../../FintechSubscriptionV3Flow/FintechSubscriptionV3Flow";
import { MaintenanceBanner } from "../../Maintenance/MaintenanceBanner";
import { CorpMultiAccountSwitcherModal } from "../../MultiAccount/CorpMultiAccountSwitcherModal/component";
import { useSwitchBusinessAccount } from "../../MultiAccount/useSwitchBusinessAccount";
import { MULTI_ACCOUNT_MODAL_TITLE } from "./constants";
import { useUtmParams } from "../../../../context/userSource";
import "./styles.scss";

interface IHeaderProps {
  language: string;
  locationPath: string;
  rewardsAccounts?: RewardsAccount[];
  selectedRewardsAccountId: string | null;
  setSelectedRewardsAccountId: (id: string | null) => void;
  notAuthenticated?: boolean;
  canViewConsumer: boolean;
  corpBusinessAccounts?: CorpBusinessAccount[];
}

const DesktopHeader = ({
  locationPath,
  rewardsAccounts,
  selectedRewardsAccountId,
  setSelectedRewardsAccountId,
  notAuthenticated,
  canViewConsumer,
  corpBusinessAccounts,
}: IHeaderProps) => {
  const {
    policies,
    sessionInfo,
    modalManagerProps: { activeModal, closeActiveModal },
  } = useUserContext("capone-corporate");
  const { matchesMobile } = useDeviceTypes();
  const { handleChangeBusinessInUse, isAccountSwitchLoading } =
    useSwitchBusinessAccount();

  const [selectedSurveyOption, setSelectedSurveyOption] = useState(-1);

  const currentAccount = rewardsAccounts?.find(
    (account) => account.accountReferenceId === selectedRewardsAccountId
  );

  const maintenanceExperiment = useExperimentsById("corp-traveler-maintenance");
  const isMaintenaceBannerActive =
    Boolean(maintenanceExperiment) &&
    maintenanceExperiment?.variant === "banner";

  const CORP_HIDDEN_FUNNEL_ENTRY_HEADER_PATHS = [
    ...HIDDEN_FUNNEL_ENTRY_HEADER_PATHS,
    ...UNPROTECTED_PATHS,
  ];

  const showDBCLogo =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const isVXBOnboarding = locationPath.includes(PATH_CORP_ACKNOWLEDGEMENT);

  const handleCloseCorpAccountPickModal = () => {
    sessionStorage.setItem(HAS_CHOSEN_ACCOUNT, "true");

    closeActiveModal("multiaccount");
  };

  const hasCorpBusinessAccounts =
    corpBusinessAccounts && corpBusinessAccounts?.length > 1;

  useEffect(() => {
    if (activeModal === "seanEllis") {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          type: "sean_ellis_question",
        },
      });
    }
  }, [activeModal]);

  const surveyOptions = [
    {
      id: 1,
      label: "Very disappointed",
      tracking_property: "very_disappointed",
    },
    {
      id: 2,
      label: "Somewhat disappointed",
      tracking_property: "somewhat_disappointed",
    },
    {
      id: 3,
      label: "Not disappointed (it isn’t that useful)",
      tracking_property: "not_disappointed",
    },
  ];

  const handleSubmitSurvey = () => {
    trackEvent({
      eventName: MODAL_ALERT_CHOICE,
      properties: {
        type: "sean_ellis_question",
        choice: surveyOptions[selectedSurveyOption - 1].tracking_property,
      },
    });
    updateUserSeenModal({
      modalName: "sean_ellis",
    }).catch((e) => {
      console.error("Issue persisting seen sean ellis", e);
      closeActiveModal("seanEllis");
    });
  };

  const handleSubmitFeedback = (feedback: string, canContact: boolean) => {
    trackEvent({
      eventName: "sean_ellis_feedback",
      properties: {
        feedback,
        you_can_contact_me: canContact,
      },
    });
    closeActiveModal("seanEllis");
  };

  const handleChangeSurveyOption = (optionId: number) => {
    setSelectedSurveyOption(optionId);
  };

  const handleCloseSurveyModal = () => {
    closeActiveModal("seanEllis");
    updateUserSeenModal({
      modalName: ModalNames.SEAN_ELLIS_MODAL,
    }).catch((e: Error) => {
      console.error("Issue persisting seen sean ellis", e);
    });
  };

  return (
    <>
      <GenericHeader
        className={clsx("desktop-header", "corp", {
          b2b: !notAuthenticated,
          "unprotected-header": notAuthenticated,
        })}
        fullWidth
        left={
          <Switch>
            <Route
              path={[
                PATH_FLIGHTS,
                PATH_HOTELS,
                PATH_CARS,
                PATH_TRIPS,
                PATH_TERMS,
                PATH_TERMS_OF_SERVICE,
                PATH_SITE_TERMS_OF_USE,
                PATH_PREMIER_COLLECTION,
                PATH_TRAVEL_WALLET,
                PATH_TRAVEL_SALE,
                PATH_CORP_ACCESS_DENIED,
                PATH_CORP_ACCESS_DENIED_NOT_BETA,
                PATH_CORP_ACKNOWLEDGEMENT,
                PATH_CORP_FAQ,
              ]}
              render={(browserRouterProps: RouteComponentProps) => (
                <div
                  className={clsx("logo-container", {
                    disabled: isVXBOnboarding,
                  })}
                  onClick={() => {
                    if (isVXBOnboarding) return;
                    browserRouterProps.history.push(PATH_HOME);
                  }}
                >
                  <img
                    src={showDBCLogo ? DBCCorporateLogo : CorporateLogo}
                    data-testid="capone-corporate-header-icon"
                    alt={CAP_ONE_CORP_LOGO_ALT}
                  />
                </div>
              )}
            />
            <Route path="*" />
          </Switch>
        }
        toolbarClassName="b2b"
        right={
          notAuthenticated ? (
            <Switch>
              <Route
                path={[
                  PATH_FLIGHTS,
                  PATH_HOTELS,
                  PATH_CARS,
                  PATH_TRIPS,
                  PATH_TERMS,
                  PATH_TERMS_OF_SERVICE,
                  PATH_SITE_TERMS_OF_USE,
                  PATH_PREMIER_COLLECTION,
                  PATH_TRAVEL_WALLET,
                  PATH_TRAVEL_SALE,
                ]}
                render={(browserRouterProps: RouteComponentProps) => (
                  <CapOneHeaderHomePageRightSection
                    canViewConsumer={canViewConsumer}
                    notAuthenticated={notAuthenticated}
                    currentAccount={rewardsAccounts?.find(
                      (account) =>
                        account.accountReferenceId === selectedRewardsAccountId
                    )}
                    {...browserRouterProps}
                  />
                )}
              />
              <Route path="*" />
            </Switch>
          ) : rewardsAccounts &&
            !HIDDEN_HEADER_DETAILS_PATHS.includes(locationPath) &&
            rewardsAccounts.length > 0 ? (
            <RewardsAccountSelection
              selectedRewardsAccountId={selectedRewardsAccountId}
              setSelectedRewardsAccountId={setSelectedRewardsAccountId}
              rewardsAccounts={rewardsAccounts}
              corpBusinessAccounts={corpBusinessAccounts}
              activeAccountBusinessId={
                (sessionInfo as CorpSessionInfo).corporateInfo.businessId ?? ""
              }
              handleChangeBusinessInUse={handleChangeBusinessInUse}
              isAccountSwitchLoading={isAccountSwitchLoading}
            />
          ) : undefined
        }
        middle={
          isMaintenaceBannerActive && (
            <MaintenanceBanner
              title={maintenanceExperiment.data.title}
              subtext={maintenanceExperiment.data.subtext}
              severity={maintenanceExperiment.data.severity}
            />
          )
        }
        bottom={
          notAuthenticated ? undefined : !CORP_HIDDEN_FUNNEL_ENTRY_HEADER_PATHS.includes(
              locationPath
            ) ? (
            <Switch>
              <Route
                path={[
                  PATH_FLIGHTS,
                  PATH_HOTELS,
                  PATH_CARS,
                  PATH_TRIPS,
                  PATH_TERMS,
                  PATH_TERMS_OF_SERVICE,
                  PATH_SITE_TERMS_OF_USE,
                  PATH_PREMIER_COLLECTION,
                  PATH_TRAVEL_WALLET,
                  PATH_TRAVEL_SALE,
                ]}
                render={(browserRouterProps: RouteComponentProps) => (
                  <FunnelEntryTabs {...browserRouterProps} />
                )}
              />
              <Route path="*" />
            </Switch>
          ) : undefined
        }
      />
      {hasCorpBusinessAccounts && (
        <CorpMultiAccountSwitcherModal
          open={activeModal === "multiaccount"}
          onClose={handleCloseCorpAccountPickModal}
          headerImage={<Icon name={IconName.MultiAccountCards} />}
          title={MULTI_ACCOUNT_MODAL_TITLE}
          accounts={corpBusinessAccounts}
          sessionInfo={sessionInfo as CorpSessionInfo}
          handleChangeBusinessInUse={handleChangeBusinessInUse}
          isAccountSwitchLoading={isAccountSwitchLoading}
        />
      )}
      {policies && sessionInfo && (
        <OnboardingModal policies={policies} currentAccount={currentAccount} />
      )}
      {!matchesMobile && (
        <FintechSubscriptionV3Flow
          onClose={() => closeActiveModal("fintechv3")}
          open={activeModal === "fintechv3"}
        />
      )}

      {isCorpTenant(config.TENANT) && (
        <CorpSurveyModal
          isOpen={activeModal === "seanEllis"}
          onClose={handleCloseSurveyModal}
          title="How would you feel if you could no longer use Capital One Travel for Business?"
          subtitle="Don't worry, we're not going anywhere! Answering helps us improve
              our product for valued customers like you. (Optional)"
          feedbackTitle="Thanks for your feedback!"
          feedbackSubtitle="We’re constantly working to meet your expectations.
Tell us a bit more to help us improve our product."
          image={<Icon name={IconName.SurveyQuestionMark} />}
          options={surveyOptions}
          onSubmitOptions={handleSubmitSurvey}
          onSubmitFeedback={handleSubmitFeedback}
          onOptionChange={handleChangeSurveyOption}
          selectedOptionId={selectedSurveyOption}
          trackEvent={trackEvent}
        />
      )}
    </>
  );
};

interface ICapOneHeaderHomePageRightSectionProps extends RouteComponentProps {
  displayOnlyIcon?: boolean;
  notAuthenticated?: boolean;
  currentAccount: RewardsAccount | undefined;
  canViewConsumer: boolean;
}

export const CapOneHeaderHomePageRightSection = (
  props: ICapOneHeaderHomePageRightSectionProps
) => {
  const {
    displayOnlyIcon,
    notAuthenticated,
    canViewConsumer,
    currentAccount,
    ...routerProps
  } = props;
  const { history } = routerProps;
  const { pathname } = history.location;
  const { sessionInfo } = useUserContext("capone-corporate");

  const expState = useExperiments();
  const utmParams = useUtmParams();

  const [openSupportModal, setOpenSupportModal] = useState(false);
  const [offerCount, setOfferCount] = useState(0);
  const onCarsPage = matchPath(pathname, PATH_CARS);
  const onFlightsPage = matchPath(pathname, PATH_FLIGHTS);
  const onHotelsPage = matchPath(pathname, PATH_HOTELS);
  const onTripsPage = matchPath(pathname, PATH_TRIPS);
  const onWalletPage = matchPath(pathname, PATH_TRAVEL_WALLET);
  const dropdownActions: DropdownAction[] = [];

  const consumerPortalUrl = getUrlWithUtmParams(
    config.consumerPortalUrl || "",
    utmParams
  );

  const adminPortalUrl = getUrlWithUtmParams(
    config.adminPortalUrlWithSource,
    utmParams
  );

  const isTravelWalletOfferExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_OFFER_EXPERIMENT
    ) === AVAILABLE;

  const isTravelWalletCreditsExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_CREDIT_EXPERIMENT
    ) === AVAILABLE;

  const onLogout = useCallback(() => {
    history.push(B2B_PORTAL_ENDSESSION_PATH);
  }, [history]);

  const showSupportModal = () => {
    setOpenSupportModal(true);
  };

  useEffect(() => {
    document.addEventListener(CustomEvents.showSupportModal, showSupportModal);
    return () => {
      document.removeEventListener(
        CustomEvents.showSupportModal,
        showSupportModal
      );
    };
  }, []);

  /**
   * @description Dispatches a custom event to communicate with Pawtucket
   * modules. This event is handled in flights/hotels/cars Body component.
   */
  const onEditTravelerClick = useCallback(
    (iconButton = false) => {
      let editTravelersModalState = EditTravelerModalState.closed;

      if (onCarsPage) {
        editTravelersModalState = EditTravelerModalState.ground;
      } else if (onFlightsPage) {
        editTravelersModalState = EditTravelerModalState.flight;
      } else if (onHotelsPage) {
        editTravelersModalState = EditTravelerModalState.hotel;
      } else if (onTripsPage) {
        editTravelersModalState = EditTravelerModalState.trips;
      } else if (onWalletPage) {
        editTravelersModalState = EditTravelerModalState.wallet;
      }

      const event = new CustomEvent<any>(
        CustomEvents.editTravelersStateChange,
        {
          detail: editTravelersModalState,
        }
      );

      document.dispatchEvent(event);

      trackEvent({
        eventName: SELECT_EDIT_TRAVELERS,
        properties: {
          source: iconButton ? "traveler_nav" : "dropdown, header",
        } satisfies SelectEditTravelersProperties,
      });
    },
    [onCarsPage, onFlightsPage, onHotelsPage, onTripsPage]
  );

  const handleSupportModalClose = () => setOpenSupportModal(false);

  // only show this action in product pages until edit travelers modal is ready
  // on other pages.
  if (onCarsPage || onFlightsPage || onHotelsPage) {
    dropdownActions.push({
      divider: true,
      label: "Edit Travelers",
      onClick: onEditTravelerClick,
    });
  }

  useEffect(() => {
    fetchTravelWalletOffers().then((response) => {
      const hasCredit = !!(response as WalletSummaryResponse).credit;
      const offerCount = (response as WalletSummaryResponse).offerCount || 0;
      const totalCount = hasCredit ? offerCount + 1 : offerCount;
      setOfferCount(totalCount);
    });
  }, []);

  const trackPortalSwitched = (portal: PortalTypes) => {
    trackEvent({
      eventName: PORTAL_SWITCHED,
      properties: {
        current_portal: "corporate",
        portal_selected: portal,
      } satisfies PortalSwitchedProperties,
    });
  };

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const links = useMemo(
    () => [
      ...(isTravelWalletCreditsExperiment
        ? [
            {
              type: HeaderMenuLinkType.Wallet,
              selected: history.location.pathname === PATH_TRAVEL_WALLET,
              content: (
                <Typography className="my-travel-offers-link">
                  {getTravelCreditAndOfferTitle(!isCorpHideTravelOffers)}
                </Typography>
              ),
              onClick: () => {
                routerProps.history.push(PATH_TRAVEL_WALLET);
              },
              badgeContent: offerCount,
              displayOnlyIcon,
            },
          ]
        : isTravelWalletOfferExperiment
        ? [
            {
              type: HeaderMenuLinkType.Offer,
              selected: history.location.pathname === PATH_TRAVEL_WALLET,
              content: (
                <Typography className="my-travel-offers-link">
                  My travel offers
                </Typography>
              ),
              onClick: () => {
                routerProps.history.push(PATH_TRAVEL_WALLET);
              },
              badgeContent: offerCount,
              displayOnlyIcon,
            },
          ]
        : []),
      {
        type: HeaderMenuLinkType.Trips,
        selected: history.location.pathname === PATH_TRIPS,
        content: <Typography className="my-trips-link">My Trips</Typography>,
        onClick: () => {
          routerProps.history.push(PATH_TRIPS);
        },
        dropdownActions: [],
        displayOnlyIcon,
      },
      {
        type: HeaderMenuLinkType.Travelers,
        selected: false,
        content: <Typography>Traveler profiles</Typography>,
        onClick: () => onEditTravelerClick(true),
        dropdownActions: [],
        displayOnlyIcon,
      },
      {
        type: HeaderMenuLinkType.Support,
        selected: false,
        content: <Typography>{textConstants.SUPPORT_LINK}</Typography>,
        onClick: () => {
          trackEvent({
            eventName: VIEWED_CONTACT_MODAL,
            properties: { contact_reason: "general_contact" },
          });
          setOpenSupportModal(true);
        },
        dropdownActions: [],
        displayOnlyIcon,
      },
      {
        type: HeaderMenuLinkType.CorporateMenu,
        selected: false,
        content: displayOnlyIcon ? (
          <>
            <Icon name={IconName.B2BUser} />
            <Typography>{` ${sessionInfo?.userInfo?.firstName}`}</Typography>
          </>
        ) : (
          <Typography>{`Welcome, ${sessionInfo?.userInfo?.firstName}`}</Typography>
        ),
        onClick: onLogout,
        dropdownActions: [
          {
            label: `${sessionInfo?.userInfo?.firstName} ${sessionInfo?.userInfo?.lastName}`,
            onClick: onEditTravelerClick,
          },
        ],
      },
    ],
    [
      displayOnlyIcon,
      history.location.pathname,
      isTravelWalletCreditsExperiment,
      isTravelWalletOfferExperiment,
      offerCount,
      onEditTravelerClick,
      onLogout,
      routerProps.history,
      sessionInfo?.userInfo?.firstName,
      sessionInfo?.userInfo?.lastName,
    ]
  );

  const UnprotectedPathHeaderRightSection = () => (
    <Box className="non-auth-header-right-section-container">
      <Box className="non-auth-header-right-section-support">
        <Typography className="support-text">
          {textConstants.QUESTIONS_ABOUT_CAP1_TRAVEL}
        </Typography>
        <ActionLink
          content={
            <Typography className="support-modal-link-text">
              {textConstants.GET_HELP}
            </Typography>
          }
          onClick={() => {
            trackEvent({
              eventName: VIEWED_CONTACT_MODAL,
              properties: { contact_reason: "general_contact" },
            });
            setOpenSupportModal(true);
          }}
          className="support-modal-link"
        />
      </Box>
      <ActionButton
        onClick={() => {
          history.push(PATH_AUTH_INVALID_SESSION);
        }}
        message={textConstants.SIGN_IN}
        className="b2b non-auth-login-button"
      />
    </Box>
  );
  return (
    <>
      {notAuthenticated ? (
        <UnprotectedPathHeaderRightSection />
      ) : (
        <HeaderHomePageRightSection
          links={links}
          {...routerProps}
          className="b2b"
          consumerPortalUrl={consumerPortalUrl}
          adminPortalUrl={adminPortalUrl}
          canViewAdmin={
            (sessionInfo as CorpSessionInfo)?.corporateInfo.permissions
              .canViewAdmin
          }
          canViewConsumer={canViewConsumer}
          tenant="capone-corporate"
          trackPortalSwitched={trackPortalSwitched}
          onCloseHelpTip={() =>
            updateUserSeenModal({ modalName: ModalNames.VXB_HELPTIP })
          }
        />
      )}
      <DesktopPopupModal
        open={openSupportModal}
        className="desktop-contact-support-popup"
        onClose={handleSupportModalClose}
        invisibleBackdrop={false}
      >
        <Box className="desktop-contact-support-popup-content">
          <SupportModalContent />
        </Box>
      </DesktopPopupModal>
    </>
  );
};

export default DesktopHeader;
