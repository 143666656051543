import React, { useEffect } from "react";
import dayjs from "dayjs";
import {
  Box,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { IPerson } from "redmond";
import {
  getDateTimeWithFormat,
  ActionButton,
  TravelerSelectRowUpdated,
} from "halifax";
import { StepProps } from "../types";
import Header from "../Header";

import baseClasses from "../../styles.module.css";
import classNames from "./styles.module.css";

interface IProps extends StepProps {
  travelers: IPerson[];
  isLoadingTravelers: boolean;
  selectedTraveler?: IPerson;
  onSelectTraveler: (traveler: IPerson) => void;
}

const ListTravelers = ({
  primaryAction,
  secondaryAction,
  onClose,
  travelers,
  isLoadingTravelers,
  selectedTraveler,
  onSelectTraveler,
}: IProps): React.ReactElement => {
  useEffect(() => {
    if (!isLoadingTravelers && !travelers.length) {
      primaryAction();
    }
  }, [isLoadingTravelers, primaryAction, travelers]);

  const getTravelerType = (traveler: IPerson) => {
    const age = dayjs().diff(
      getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
      "year"
    );

    if (age > 11) {
      return "Adult";
    }

    if (age >= 2) {
      return "Child";
    }

    return "Infant";
  };

  return (
    <Box className={baseClasses.stepWrap}>
      <Header
        title="Create your traveler profile"
        subtitle="Select from an existing traveler profile below, or create a new one. Please make sure all the information added matches your government issued ID as this will be applied to travel bookings moving forward."
        currentStep={0}
        onClose={onClose}
        showDivider
      />
      <DialogContent
        id="onboarding-description"
        role="radiogroup"
        aria-labelledby="traveler-list-label"
      >
        <Typography
          variant="h4"
          component="h3"
          className={classNames.listTitle}
          id="traveler-list-label"
        >
          Select from an existing profile or create new:
        </Typography>
        <Box className={classNames.travelersList}>
          {travelers.map((traveler) => (
            <TravelerSelectRowUpdated
              fullName={`${traveler.givenName} ${traveler.surname}`}
              key={traveler.id}
              isSelected={selectedTraveler?.id === traveler.id}
              onClickSelect={() => onSelectTraveler(traveler)}
              travelerType={getTravelerType(traveler)}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <ActionButton
          dataTestId="onboarding-list-traveler-secondary-cta"
          onClick={secondaryAction}
          defaultStyle="h4r-secondary"
          message="Create new profile"
        />
        <ActionButton
          dataTestId="onboarding-list-traveler-primary-cta"
          onClick={() => primaryAction()}
          message="Continue"
          defaultStyle="h4r-primary"
          disabled={!selectedTraveler}
        />
      </DialogActions>
    </Box>
  );
};

export default ListTravelers;
