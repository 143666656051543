import axios from "axios";
import { RecentlySearchedPackagesResponse } from "redmond";
import { config, recentlyViewedAxiosCancelToken } from "../../config";
import { recentlySearchedApiPrefix } from "../../paths";

const END_POINT: string = `${recentlySearchedApiPrefix}/packages`;

export const fetchRecentlySearchedPackages =
  (): Promise<RecentlySearchedPackagesResponse> =>
    new Promise((resolve, reject) => {
      const fetchData = async () => {
        try {
          const res = await axios.get(END_POINT, {
            baseURL: config.baseURL,
            cancelToken: recentlyViewedAxiosCancelToken.token,
          });
          resolve(res.data);
        } catch (e) {
          if (axios.isCancel(e)) {
            // do not reject if the error is a cancellation from the cancel token
            return;
          }
          reject(e);
        }
      };
      fetchData();
    });
