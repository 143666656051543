import axios from "axios";
import { config, recentlyViewedAxiosCancelToken } from "../../config";

import { AvailabilityRequest, AvailabilityResponse } from "redmond";
import { hotelAvailabilityApiPrefix } from "../../paths";

export const fetchHotelAvailability = (
  body: AvailabilityRequest
): Promise<AvailabilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(hotelAvailabilityApiPrefix, body, {
        baseURL: config.baseURL,
        cancelToken: recentlyViewedAxiosCancelToken.token,
      });

      resolve(res.data);
    } catch (e) {
      if (axios.isCancel(e)) {
        // do not reject if the error is a cancellation from the cancel token
        return;
      }
      reject(e);
    }
  });
