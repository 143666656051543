import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  InfoOutlined,
  RefreshRounded,
  ReportProblemRounded,
} from "@material-ui/icons";
import { Button, CircularProgress } from "@material-ui/core";
import {
  BOOKING_PORTAL_REFERRER_SOURCE,
  getReferrerSource,
  ReferrerSource,
  VXB,
} from "@capone/common";
import { getATCCardArt } from "halifax";
import { CorpBusinessAccount, VXB_ACKNOWLEDGED } from "redmond";
import { fetchBusinessAccounts } from "../../../api/v1/multi-account/fetchBusinessAccounts";
import { optIn } from "../../../api/v1/onboarding/optIn";
import {
  PATH_AUTH_INVALID_SESSION,
  PATH_HOTELS,
  PATH_TRAVEL_POLICIES,
} from "../../../utils/urlPaths";
import CorporateLogoWhite from "../../../assets/b2b/ftux/capone-corp-icon.svg";
import { CAP_ONE_CORP_LOGO_ALT } from "../../../utils/constants";
import config from "../../../utils/capone-corporate/config";
import classNames from "./styles.module.css";
import { trackEvent } from "../../../api/v1/trackEvent";
import { changeBusinessInUse } from "../../../api/v1/multi-account/changeBusinessInUse";

export const VXBOnboarding = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [accountsToOptIn, setAccountsToOptIn] = useState<CorpBusinessAccount[]>(
    []
  );
  const [isMultiAccount, setIsMultiAccount] = useState(false);

  const optInAccounts = async () => {
    setIsLoading(true);
    try {
      const optInPromises = accountsToOptIn.map((account) =>
        optIn(account.rewardsAccount.accountReferenceId)
      );

      const response = await Promise.all(optInPromises);
      const { businessId } = response[0];

      if (businessId && isMultiAccount) {
        await changeBusinessInUse({
          businessId,
        });
      }
      setIsError(false);
      trackEvent({
        eventName: "vxb_optin_complete",
        properties: { success: true },
      });
    } catch (error) {
      setIsError(true);
      trackEvent({
        eventName: "vxb_optin_complete",
        properties: { success: false },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBusinessAccounts()
      .then((corpBusinessAccounts) => {
        const vxbAccountsToOptIn = corpBusinessAccounts?.filter(
          (account) =>
            account.rewardsAccount.productDisplayName === VXB &&
            !("optedIn" in account)
        );

        if (vxbAccountsToOptIn.length === 0) {
          history.push(PATH_AUTH_INVALID_SESSION);
          return;
        }

        const optedInAccounts = corpBusinessAccounts.filter(
          (account) => "optedIn" in account
        );

        setAccountsToOptIn(vxbAccountsToOptIn);
        setIsMultiAccount(optedInAccounts.length > 0);
      })
      .catch(() => history.push(PATH_AUTH_INVALID_SESSION));
  }, []);

  useEffect(() => {
    if (accountsToOptIn.length > 0) {
      optInAccounts();
    }
  }, [accountsToOptIn]);

  const renderCTA = () => {
    if (isLoading) {
      return (
        <div className={classNames.loadingContainer}>
          <CircularProgress size={48} />
          <h2 className={classNames.loadingTitle}>
            Loading your business account
          </h2>
          <span className={classNames.loadingSubtitle}>
            Please do not exit or refresh this page.
          </span>
        </div>
      );
    }

    if (isError) {
      return (
        <div className={classNames.errorContainer}>
          <ReportProblemRounded />
          <div className={classNames.errorContent}>
            <h2>An unknown error occurred</h2>
            <span>
              Your account was unable to be created, please try again.
            </span>
          </div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshRounded />}
            onClick={() => optInAccounts()}
          >
            Try again
          </Button>
        </div>
      );
    }

    return (
      <div className={classNames.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          data-testid="start-booking-travel-cta"
          onClick={async () => {
            await trackEvent({
              eventName: VXB_ACKNOWLEDGED,
              properties: {
                acknowledgement_selection: "start_booking_travel",
              },
            });

            window.location.href = `${PATH_HOTELS}?${getReferrerSource(
              ReferrerSource.VxbAcknowledgement
            )}`;
          }}
        >
          Start booking travel
        </Button>
        <Button
          component="a"
          href={`${config.adminPortalUrl}${PATH_TRAVEL_POLICIES}?${BOOKING_PORTAL_REFERRER_SOURCE}`}
          variant="outlined"
          color="primary"
          onClick={async () =>
            trackEvent({
              eventName: VXB_ACKNOWLEDGED,
              properties: {
                acknowledgement_selection: "continue_to_setup",
              },
            })
          }
        >
          Continue to setup
        </Button>
      </div>
    );
  };

  return (
    <div className={classNames.vxbOnboardingContainer}>
      <div className={classNames.modalContainer}>
        <div className={classNames.titleContainer}>
          <img
            className={classNames.logo}
            src={CorporateLogoWhite}
            alt={CAP_ONE_CORP_LOGO_ALT}
          />
          <h1 className={classNames.title}>
            Take control of your business travel with smart tools that help your
            business save time and money, all in one place.
          </h1>
        </div>
        <div className={classNames.contentContainer}>
          <div className={classNames.cardContainer}>
            <img
              className={classNames.cardArtImage}
              src={getATCCardArt("VentureXBusiness")}
              alt="Venture X Business card art"
            />
            <p>
              <strong>VentureX Business cardholders</strong> enjoy free access
              to both Capital One Travel and Capital One Business Travel.
            </p>
          </div>

          <ul className={classNames.list}>
            <li>
              <strong>Save up to 10%</strong> on hotels with our discounted
              corporate rates.
            </li>
            <li>
              Manage travelers, bookings and expenses{" "}
              <strong>on 1 platform.</strong>
            </li>
            <li>
              Earn <strong>loyalty points</strong> with your favorite hotel
              brands.
            </li>
          </ul>
          <div className={classNames.alert}>
            <InfoOutlined />
            <span>
              <b>Rewards earn is different for Capital One Business Travel:</b>{" "}
              Earn 2X miles on flight and 5X miles on hotels/cars, but save up
              to 10% on hotels and unlock powerful new features.
            </span>
          </div>
          <div aria-live="polite" className={classNames.ctaContainer}>
            {renderCTA()}
          </div>
        </div>
      </div>
    </div>
  );
};
