import { CorpSessionInfo, SessionInfo } from "redmond";
import fetchCorpUsersList from "../../../../api/v1/user/fetchCorpUsersList";
import { fetchUserPassengers } from "../../../../api/v1/user-passengers/fetchUserPassengers";
import { buildCorpTravelers } from "../../../../utils/capone-corporate/travelersMappingUtils";

export const getCorpPassengerList = async (
  sessionInfo: CorpSessionInfo | SessionInfo
) => {
  const canViewUsers =
    "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.permissions.canViewUsers
      : false;

  const travelerDefaultTravelerId =
    ("corporateInfo" in sessionInfo &&
      sessionInfo.corporateInfo.defaultTravelerId) ||
    "";

  const [corpUsersList, userPassengers] = await Promise.all([
    canViewUsers
      ? fetchCorpUsersList()
      : Promise.resolve({
          users: [
            {
              id: sessionInfo.userId,
              firstName: sessionInfo.userInfo.firstName,
              lastName: sessionInfo.userInfo.lastName,
              defaultTravelerId: travelerDefaultTravelerId,
            },
          ],
        }),
    fetchUserPassengers({}),
  ]);

  const hiddenTravelerIds =
    "corporateInfo" in sessionInfo
      ? (sessionInfo as CorpSessionInfo).corporateInfo.hiddenTravelerIds
      : [];

  const loggedInUserId = sessionInfo.userId;

  return buildCorpTravelers(
    userPassengers,
    corpUsersList.users,
    hiddenTravelerIds,
    loggedInUserId
  );
};
