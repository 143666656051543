import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  SwipeableDrawer,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CorpBusinessAccount } from "redmond/apis/tysons/businesses";
import {
  CorpAccountSwitcherItem,
  sortAccountsByActiveId,
  useDeviceTypes,
} from "halifax";
import {
  CORP_SWITCH_ACCOUNT,
  CORP_SWITCH_ACCOUNT_CONTINUE,
  CorpSessionInfo,
  CustomerAccountRole,
  MODAL_ALERT,
  MODAL_ALERT_CHOICE,
} from "redmond";
import { HAS_CHOSEN_ACCOUNT } from "@capone/common";
import { trackEvent } from "../../../../api/v1/trackEvent";
import { sortBusinessAccountsByOptIn } from "../utils";

export interface CorpMultiAccountSwitcherModalProps {
  open: boolean;
  onClose: () => void;
  headerImage?: JSX.Element;
  title: string;
  accounts: CorpBusinessAccount[];
  sessionInfo: CorpSessionInfo;
  isAccountSwitchLoading: boolean;
  handleChangeBusinessInUse: (selectedAccount: CorpBusinessAccount) => void;
  variant?: "modal" | "switcher";
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    maxWidth: "546px",
    maxHeight: "665px",
  },
  drawer: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(2.5),
  },
  accountsContent: {
    padding: theme.spacing(1),
  },
  accountsList: {
    maxHeight: "30vh",
    overflowY: "auto",
    paddingBottom: 0,
    paddingTop: 0,
  },
  ctaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 1, 2.5, 1),
  },
}));

export const CorpMultiAccountSwitcherModal: React.FC<
  CorpMultiAccountSwitcherModalProps
> = ({
  open,
  onClose,
  headerImage,
  title,
  accounts,
  sessionInfo,
  variant = "modal",
  isAccountSwitchLoading,
  handleChangeBusinessInUse,
}) => {
  const classes = useStyles();
  const { matchesMobile } = useDeviceTypes();
  const [hasScroll, setHasScroll] = useState(false);

  const activeAccount =
    accounts.find(
      (account) =>
        account.optedIn?.businessId === sessionInfo.corporateInfo.businessId
    ) || accounts[0];

  const [selectedAccount, setSelectedAccount] =
    useState<CorpBusinessAccount>(activeAccount);

  const dialogContentRef = useRef<HTMLElement>(null);

  const nonOptedInSectionLabel = "Eligible for Capital One Business Travel:";

  const { optedInAccounts, nonOptedInAccounts } =
    sortBusinessAccountsByOptIn(accounts);

  const orderedOptedInAccounts = sortAccountsByActiveId(
    optedInAccounts,
    activeAccount.rewardsAccount.accountReferenceId
  );

  const modalNonDeclinedAccounts = nonOptedInAccounts.filter(
    (account) => !account.declineStatus
  );

  const currentEntryPointLocation =
    window.location.pathname.split("/")[1] || "";

  useEffect(() => {
    if (open) {
      trackEvent({
        eventName: matchesMobile ? CORP_SWITCH_ACCOUNT : MODAL_ALERT,
        properties: {
          type: "select_account_modal",
          entry_point: `traveler_portal_${currentEntryPointLocation}`,
        },
      });
    }
  }, [open]);

  const handleSwitchAccount = () => {
    const isSameAccount =
      selectedAccount.rewardsAccount.accountReferenceId ===
      activeAccount.rewardsAccount.accountReferenceId;

    trackEvent({
      eventName: matchesMobile
        ? CORP_SWITCH_ACCOUNT_CONTINUE
        : MODAL_ALERT_CHOICE,
      properties: {
        type: "select_account_modal",
        entry_point: `traveler_portal_${currentEntryPointLocation}`,
        opted_in: selectedAccount && "optedIn" in selectedAccount,
      },
    });

    if (isSameAccount) {
      onClose();
      return;
    }

    handleChangeBusinessInUse(selectedAccount);
  };

  const handleModalClose = () => {
    sessionStorage.setItem(HAS_CHOSEN_ACCOUNT, "true");
    onClose();
  };

  useEffect(() => {
    const checkScroll = () => {
      const element = dialogContentRef.current;
      if (element) {
        const hasVerticalScroll = element.scrollHeight > element.clientHeight;
        setHasScroll(hasVerticalScroll);
      }
    };

    checkScroll();
  }, [optedInAccounts, nonOptedInAccounts]);

  const renderModalView = () => (
    <Dialog
      open={open}
      onClose={handleModalClose}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "35px",
          ...(matchesMobile && { paddingBottom: 0 }),
        }}
        disableTypography
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: matchesMobile ? "100%" : "330px",
            rowGap: "10px",
          }}
        >
          {headerImage}
          <Typography
            variant="h2"
            align="center"
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent
        ref={dialogContentRef}
        style={{
          padding: "0 30px",
          ...(matchesMobile && { padding: "0 15px" }),
        }}
      >
        <List
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {orderedOptedInAccounts.map((account) => (
            <CorpAccountSwitcherItem
              account={account}
              selected={
                selectedAccount.rewardsAccount.accountReferenceId ===
                account.rewardsAccount.accountReferenceId
              }
              onChange={() => {
                setSelectedAccount(account);
              }}
            />
          ))}
        </List>

        {modalNonDeclinedAccounts.length > 0 && (
          <>
            <Typography
              style={{
                fontStyle: "italic",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#10253F",
                margin: "14px 20px 8px",
              }}
            >
              {nonOptedInSectionLabel}
            </Typography>

            <List
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {modalNonDeclinedAccounts.map((account) => (
                <CorpAccountSwitcherItem
                  account={account}
                  selected={
                    selectedAccount.rewardsAccount.accountReferenceId ===
                    account.rewardsAccount.accountReferenceId
                  }
                  onChange={() => {
                    setSelectedAccount(account);
                  }}
                />
              ))}
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "center",
          padding: "20px 0",
          boxShadow: hasScroll ? "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" : "none",
        }}
      >
        <Button
          onClick={handleSwitchAccount}
          data-testid="multiaccount-select-cta"
          variant="contained"
          color="primary"
          style={{
            width: "160px",
            ...(matchesMobile && {
              width: "100%",
              fontSize: "20px",
              margin: "0 15px",
              padding: "15px 16px",
            }),
          }}
        >
          {isAccountSwitchLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Continue"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderMobileSwitcherView = () => (
    <SwipeableDrawer
      onClose={handleModalClose}
      onOpen={() => {}}
      open={open}
      anchor="bottom"
      swipeAreaWidth={0}
      disableSwipeToOpen
      classes={{
        paper: classes.drawer,
      }}
    >
      <Box className={classes.header}>
        <Typography
          variant="h6"
          style={{
            fontSize: "20px",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Box className={classes.accountsContent}>
        <List className={classes.accountsList}>
          {optedInAccounts.map((account) => (
            <CorpAccountSwitcherItem
              account={account}
              selected={
                selectedAccount.rewardsAccount.accountReferenceId ===
                account.rewardsAccount.accountReferenceId
              }
              onChange={() => {
                setSelectedAccount(account);
              }}
              onSwitcher
            />
          ))}
        </List>
        {nonOptedInAccounts.length > 0 && (
          <>
            <Typography
              style={{
                fontStyle: "italic",
                fontSize: "12px",
                fontWeight: "bold",
                color: "#10253F",
                paddingLeft: "10px",
              }}
            >
              {nonOptedInSectionLabel}
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <List className={classes.accountsList}>
                {nonOptedInAccounts.map((account) => (
                  <CorpAccountSwitcherItem
                    account={account}
                    selected={
                      selectedAccount.rewardsAccount.accountReferenceId ===
                      account.rewardsAccount.accountReferenceId
                    }
                    onChange={() => {
                      setSelectedAccount(account);
                    }}
                    onSwitcher
                  />
                ))}
              </List>
            </Box>
          </>
        )}
        <Box className={classes.ctaContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSwitchAccount}
            style={{
              width: "90%",
              fontSize: "20px",
            }}
            aria-label="Select account"
          >
            {isAccountSwitchLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Continue"
            )}
          </Button>
          {activeAccount.rewardsAccount.customerAccountRole ===
            CustomerAccountRole.Primary && (
            <Typography
              component="p"
              style={{
                color: "#606060",
                fontSize: "12px",
                fontStyle: "italic",
                marginTop: "10px",
              }}
            >
              Rewards balances are visible to Primary cardholders only.
            </Typography>
          )}
        </Box>
      </Box>
    </SwipeableDrawer>
  );

  return matchesMobile && variant === "switcher"
    ? renderMobileSwitcherView()
    : renderModalView();
};
